@import "../../index.scss";

.our-app-account-card {
  display: flex;
  flex-direction: column;
  background: transparent;
  align-self: center;
  cursor: pointer;

  .our-app-account-card-header {
    z-index: 1;
    display: flex;
    // margin-top: 1VH;
    flex-direction: column;
    gap: 1.822vw;

    @media screen and (max-width: 850px) {
      padding-inline-start: 3vw;
    }

    @media screen and (max-width: 576px) {
      flex-direction: row;
      align-items: center;
      gap: 8vw;
      padding-inline-start: 0vw;
    }
  }

  .our-app-account-card-body {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .our-app-account-card-icon-container {
    width: 3.5vw;
    height: 3.5vw;
    min-width: 58px;
    min-height: 58px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    background: var(--secondary-color);

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      min-height: 29.25px;
      min-width: 24.4px;

      @media screen and (max-width: 1600px) {
        min-width: calc(24.4px * 0.75);
        min-height: calc(29.25px * 0.75);
      }

      @media screen and (max-width: 1280px) {
        min-width: calc(24.4px * 0.625);
        min-height: calc(29.25px * 0.625);
      }

      @media screen and (max-width: 992px) {
        min-width: calc(24.4px * 0.516);
        min-height: calc(29.25px * 0.516);
      }
    }

    @media screen and (max-width: 576px) {
      flex-shrink: 0;
      width: 11.54vw;
      height: 11.54vw;
      max-width: 60px;
      max-height: 60px;
      min-height: 40px;
      min-width: 40px;
    }
  }

  .our-app-account-card-desc-container {
    max-height: 22vh;
    overflow: auto;

    p {
      // overflow: auto;
      // max-height: 15vh;
      font-weight: 600;
      font-size: 2vh;
      overflow: auto;

      @media screen and (max-width: 992px) {
        font-size: 1.7vh;
      }
    }

    @media screen and (min-width: 1600px) {
      max-height: 24vh;
    }
  }

  .our-app-account-card-desc-container::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  .our-app-account-card-title-container {
    p {
      font-size: 1.04vw;
      font-weight: 600;
      margin-bottom: 10px;

      margin-bottom: 5px !important;

      @media screen and (max-width: 992px) {
        font-size: 18px;
        margin-bottom: 6px;
        // width: 90%;
      }
    }
  }

  @media screen and (max-width: 850px) {
    align-self: auto;
    margin-inline-start: 3.4vh;
  }

  @media screen and (max-width: 675px) {
    align-self: auto;
    width: 100%;
    margin-inline-start: 0rem;
  }
}

.our-app-account-card-active {
  width: 34vw;
  height: 49vh;
  display: flex;
  flex-direction: column;
  background: var(--white-color);
  cursor: pointer;
  padding: 1.7vh;
  padding-bottom: 3.4vh;
  position: relative;
  border-radius: 70px;
  -webkit-border-radius: 70px;
  -moz-border-radius: 70px;
  -ms-border-radius: 70px;
  -o-border-radius: 70px;
  box-shadow: 0px 4px 20px rgba(196, 200, 208, 0.5);
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  top: 3vw;

  .our-app-account-card-header {
    // margin-top: 1VH;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 1.822vw;

    // gap:1.7vh;
    @media screen and (max-width: 1600px) {
      gap: 0.5vw;
    }
  }

  .our-app-account-card-body {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .our-app-account-card-title-container {
    p {
      font-size: 1.30208vw;
      font-weight: 600;
      margin-bottom: 10px;
      color: var(--primary-color);
      margin-bottom: 7px !important;

      @media screen and (max-width: 992px) {
        font-size: 18px;
        margin-bottom: 6px;
      }
    }
  }

  .our-app-account-card-desc-container {

    // max-height: 22vh;
    // overflow: auto;
    p {
      font-size: 1.041vw;
      font-weight: 600;
      line-height: 1.4;
      margin-bottom: 5px !important;
      overflow: auto;

      @media screen and (max-width: 992px) {
        font-size: 18px;
        margin-bottom: 6px;
      }
    }

    @media screen and (min-width: 1600px) {
      max-height: 24vh;
    }
  }

  .our-app-account-card-keywords {
    color: var(--primary-color) !important;
    font-weight: 600;

    // margin-top: 2VH;
  }

  .our-app-account-card-icon-container {
    width: 5.1vw;
    height: 5.1vw;
    // min-width: 78px;
    // min-height: 78px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    background: var(--light-primary-color);

    img {
      // aspect-ratio: 4/2;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }

    @media screen and (max-width: 1600px) {}

    @media screen and (max-width: 1280px) {}

    @media screen and (max-width: 992px) {}

    @media screen and (max-width: 576px) {
      min-width: 50px;
      min-height: 50px;
      width: 14.54vw !important;
      height: 14.54vw !important;
      max-width: 70px;
      max-height: 70px;
      transform: translateX(-1vw);

      img {
        width: 17vw;
      }
    }
  }

  .our-app-account-card-footer {
    position: absolute;
    bottom: 20px;

    min-width: 35px;
    min-height: 35px;
    width: 2.4vw;
    height: 2.4vw;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 20px;
    background: var(--primary-color);
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 1600px) {
    top: 19%;
  }

  @media screen and (max-width: 1280px) {
    top: 20%;
    height: 21rem;
  }

  @media screen and (max-width: 992px) {
    top: 15%;
    padding: 2.5vh;
    height: 25rem;
    width: 40vw;
  }

  @media screen and (max-width: 768px) {
    width: 85vw;
    height: 22rem;
    top: 0;

    .our-app-account-card-icon-container {
      width: 5rem;
      height: 5rem;
    }
  }

  @media screen and (max-width: 576px) {
    width: 85vw;
    height: fit-content;
    max-width: initial;
    transition: none;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    top: 0;
  }
}

.App.ar {
  .our-app-account-card-footer {
    right: unset !important;
    left: 20px;
  }
}