@import "../../../index.scss";

.terms-of-use-content-card-wrapper {
  height: 62vh;
  padding: 1.7vh;
  background: #fff;
  border-radius: 25px;
  box-shadow: 0 4px 30px rgba(196, 200, 208, 0.5);
  overflow: hidden;
  position: relative;

  .gradient-effect {
    left: 0;
  }

  .terms-of-use-content-card {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    scrollbar-width: none;
    background: var(--white-color);

    &::-webkit-scrollbar {
      width: 5px;
      height: 10vh;
      border-radius: 25px;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      position: relative;

      &::after {
        content: " ";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-50%, -50%);
        height: 80%;
        width: 10px;
        background: transparent;
        pointer-events: none;
      }
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
      box-shadow: inset 0 0 5px #dddddd;
      border-radius: 4px;
      border-left: 1.5px solid transparent;
      border-right: 1.5px solid transparent;
      border-radius: 25px;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #d0dbe5;
      border-radius: 4px;
      width: 5px;
      border-radius: 25px;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
    }

    .terms-of-use-content-card-header {
      .terms-of-use-content-card-title {
        font-size: 0.93vw;
        font-weight: 600;
        color: var(--primary-color);
        margin-bottom: 0;

        @media screen and (max-width: 850px) {
          font-size: 14px;
        }

        @media screen and (max-width: 576px) {
          font-size: 1.7vh;
        }
      }

      .terms-of-use-content-card-desc {
        font-size: 0.83vw;
        color: var(--paragraph-color);
        padding-bottom: 3rem;
        width: 97%;

        p {
          color: var(--paragraph-color);
          margin-bottom: 6rem;
        }

        @media screen and (max-width: 576px) {
          font-size: 14px;
          overflow-y: auto;
          scrollbar-width: none;

          &::-webkit-scrollbar {
            width: 5px;
            border-radius: 25px;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
            position: relative;

            &::after {
              content: " ";
              position: absolute;
              top: 50%;
              right: 0;
              transform: translate(-50%, -50%);
              height: 80%;
              width: 10px;
              background: transparent;
              pointer-events: none;
            }
          }

          /* Track */
          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px #d0dbe5;
            border-radius: 25px;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            -o-border-radius: 25px;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #d0dbe5;
            border-radius: 0px;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            -ms-border-radius: 0px;
            -o-border-radius: 0px;
          }
        }
      }
    }

    @media screen and (max-width: 1600px) {
      min-width: 100%;
      min-height: calc(62.5vh * 0.75);
    }

    @media screen and (max-width: 1280px) {
      min-width: 100%;
      min-height: calc(62.5vh * 0.625);
    }

    @media screen and (max-width: 992px) {
      min-width: 100%;
      min-height: calc(62.5vh * 0.516);
    }

    @media screen and (max-width: 992px) {
      min-width: 100%;
      min-height: 526px;
    }

    @media screen and (max-width: 576px) {
      min-width: 100%;
      width: 100%;
      border-radius: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -o-border-radius: 0;
    }
  }

  // @media screen and (max-width: 1280px) {
  //   height: 60vh;
  //   padding: 1.7vh;
  // }
  // @media screen and (max-width: 850px) {
  //   height: 65vh;
  //   padding: 1.7vh;
  // }
  @media screen and (max-width: 576px) {
    padding: 4vw;
    width: 75vw;
    height: 122.3255vw;
  }
}