.learn-more-section {
  > div {
    &:nth-child(1) {
      // height: auto !important;
    }
  }
  .container {
    height: 100%;
  }
}
