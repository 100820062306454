@import "../../index.scss";

.scroller {
  position: fixed;
  z-index: 11111;
  width: 28vw;
  top: 60%;
  display: flex;
  justify-content: space-between;
  transform: rotate(90deg);
  right: -10vw;

  .scroller-circles-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.7vh;
  }

  .InActive {
    height: 10px;
    width: 10px;
    color: transparent;
    background: transparent;
    padding: 0.3rem;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    border-color: var(--secondary-color);
  }

  .Active {
    height: 1vw;
    width: 1vw;
    color: transparent;
    background: var(--secondary-color);
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    border-color: var(--secondary-color);
  }

  .scroller-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.7vh;
    position: relative;
    transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;

    p {
      margin: 0;
      color: var(--secondary-color);
      font-weight: 600;
      position: relative;
    }

    .scroller-icon-container {
      width: 2.604vw;
      height: 2.604vw;
      min-width: 50px;
      min-height: 50px;
      border-radius: 100%;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      -ms-border-radius: 100%;
      -o-border-radius: 100%;
      background: var(--secondary-color);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        width: 18px;
        height: 12px;
      }
    }

    .scrollArrow {
      transform: rotate(-90deg);
      width: 45px;
      height: 45px;
      cursor: pointer;

      @media screen and (max-width: 1366px) {
        width: 3.4vh;
      }
    }
  }

  .isReachedTheBottom {
    left: -4vw;
  }

  &[dir="rtl"] {
    right: auto;
    left: -11vw;

    .scroller-circles-wrapper {
      order: 2;
      transform: rotate(180deg);
    }

    .scroller-wrapper {
      order: 1;

      p {
        order: 2;
      }

      .scroller-icon-container {
        order: 1;
      }
    }
  }

  @media screen and (max-width: 1600px) {
    .scroller-wrapper {
      p {
        &::before {
          width: 18vw;
          right: 125%;
        }
      }

      .scroller-icon-container {
        width: 2.4vw;
        height: 2.4vw;
        min-width: calc(50px * 0.75);
        min-height: calc(50px * 0.75);

        img {
          width: 1.7vh;
          height: 1vh;
        }
      }
    }
  }

  @media screen and (max-width: 1280px) {
    .scroller-wrapper {
      p {
        &::before {
          width: 13vw;
          right: 1.7vh;
        }
      }

      .scroller-icon-container {
        width: 2.604vw;
        height: 2.604vw;
        min-width: calc(50px * 0.625);
        min-height: calc(50px * 0.625);

        img {
          width: 1.7vh;
          height: 1vh;
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    .scroller-wrapper {
      visibility: hidden;
    }
  }
}

.mobile-scroller {
  position: fixed;
  bottom: 3.4vh;
  right: 1.7vh;
  display: flex;
  align-items: center;
  width: fit-content;
  z-index: 50;
  justify-content: end;
  gap: 1.7vh;

  p {
    margin: 0;
    font-size: 1.7vh;
    font-weight: 600;
  }

  .mobile-scroller-button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background: var(--secondary-color);
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    cursor: pointer;

    img {
      width: 15px;
    }
  }

  [dir="rtl"] {
    .mobile-scroller-button-wrapper {
      right: unset;
      left: 0;
    }
  }
}

[dir="rtl"] {
  .mobile-scroller {
    right: auto;
    left: 3vw;
  }

  .scroller-wrapper-middle {
    order: 1;
  }
}

.mobile-scroller-last-slide {
  bottom: 9rem;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
}

.show-ad-wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 100%;
  position: relative;

  .add-close-btn {
    background: #fff;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    padding: 1.7vh;
    width: 50px;
    height: 50px;
    z-index: 10000;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    left: 50%;
    position: fixed;
    bottom: 22vh;

    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
  }
}

#react-modal-image-img {
  width: 90vw;
  border-radius: 1.7vh;
  -webkit-border-radius: 1.7vh;
  -moz-border-radius: 1.7vh;
  -ms-border-radius: 1.7vh;
  -o-border-radius: 1.7vh;
}

.sub-scroller {
  justify-content: end;

  .scroller-wrapper {
    p {
      &::before {
        display: none;
      }
    }
  }
}

.scroller-wrapper-middle {
  transform: scale(-1);
  -webkit-transform: scale(-1);
  -moz-transform: scale(-1);
  -ms-transform: scale(-1);
  -o-transform: scale(-1);

  p {
    &::before {
      display: none;
    }
  }

  .scroller-icon-container {
    transform: scale(-1);
    -webkit-transform: scale(-1);
    -moz-transform: scale(-1);
    -ms-transform: scale(-1);
    -o-transform: scale(-1);

    @media screen and (max-height: 568px) and (orientation: landscape) {
      transform: scale(1);
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
    }
  }
}

.scroller-buttons-wrapper {
  display: flex;
  gap: 0.83333vw;

  .scroller-wrapper {
    gap: 0;
  }
}

[dir="rtl"] {
  .scroller-buttons-wrapper {
    flex-direction: row-reverse;
  }
}

.scroller-wrapper-FirstScreen {
  &::before {
    content: " ";
    position: absolute;
    background: var(--secondary-color);
    width: 13.9583vw;
    height: 2px;
    top: -0.04vw;
    right: 4vw;
    border-radius: 32px;
    -webkit-border-radius: 32px;
    -moz-border-radius: 32px;
    -ms-border-radius: 32px;
    -o-border-radius: 32px;
  }
}

.scroller-wrapper-LastScreen {
  &::before {
    content: " ";
    position: absolute;
    background: var(--secondary-color);
    width: 13.9583vw;
    height: 2px;
    top: -0.04vw;
    right: 0vw;
    border-radius: 32px;
    -webkit-border-radius: 32px;
    -moz-border-radius: 32px;
    -ms-border-radius: 32px;
    -o-border-radius: 32px;

    @media screen and (max-width: 1600px) {
      width: 13vw !important;
      right: 0vw !important;
    }
  }
}

.scroller-wrapper-MiddleScreen {
  &::before {
    content: " ";
    position: absolute;
    background: var(--secondary-color);
    width: 13.9583vw;
    height: 2px;
    top: -0.04vw;
    right: 5vw;
    border-radius: 32px;
    -webkit-border-radius: 32px;
    -moz-border-radius: 32px;
    -ms-border-radius: 32px;
    -o-border-radius: 32px;

    @media screen and (max-width: 1600px) {
      width: 13vw !important;
      right: 5vw !important;
    }
  }
}

.mobile-ad-wrapper {
  position: fixed;
  width: 50px;
  height: 50px;
  right: 4vw;
  z-index: 999;

  @media screen and (max-width: 992px) {
    top: 12rem;
  }
}

[dir="rtl"] {
  .mobile-ad-wrapper {
    right: auto;
    left: 4vw;
  }
}

.mobile-scroller-last-slide-mobile {
  bottom: 6rem;
}

.__react_modal_image__header {
  display: none;
}

.__react_modal_image__modal_container {
  backdrop-filter: blur(3px) !important;
  background: rgba($color: #000000, $alpha: 0.3) !important;
}

.ad-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1111111;
  backdrop-filter: blur(3px) !important;
  background: rgba($color: #000000, $alpha: 0.3) !important;

  .show-ad-container {
    position: absolute;
    width: 70vw;

    // height: 40vh;
    .ad-image {
      width: 100%;
      height: 100%;
    }

    .ad-close-btn {
      position: absolute;
      bottom: -25px;
      left: 50%;
      width: 50px;
      transform: translateX(-50%);
      cursor: pointer;
    }
  }
}