@import "../../index.scss";

.css-d7l1ni-option,
.css-tr4s17-option,
.css-10wo9uf-option {
  .language-img {
    display: none !important;
  }
}

.css-1n6sfyn-MenuList {
  padding: 1.7vh;
  border-radius: 1.7vh;

  @media (max-width: 576px) {
    padding: 0.5rem;
  }
}

.select-container {
  .css-3sxqek-menu::after {
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    z-index: 9999999999999999999;
    border-left: 1.67vh solid transparent;
    border-right: 1.67vh solid transparent;
    top: -1.2vh;
    border-bottom: 1.67vh solid white;
  }
}

.select-container.en {
  .css-3sxqek-menu::after {
    right: 1.7vh;
  }
}

.select-container.ar {
  .css-3sxqek-menu::after {
    left: 1.7vh;
  }
}

@media screen and (max-width: 675px) {
  .css-ipdz13-control {
    overflow: hidden;
  }

  .css-15lsz6c-indicatorContainer {
    padding: 0 !important;
  }

  .css-b62m3t-container {
    height: 100%;
    width: 7rem;
  }
}