@import "../../index.scss";

.learn-more-article-headline-card-container {
  position: relative;
  box-shadow: 0 0px 20px rgba(196, 200, 208, 0.5);
  background: var(--white-color);
  display: flex;
  flex-direction: column;
  padding: 1.7vh;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  padding-block: 2.5vh;
  height: 100%;
  width: 100%;
  max-height: 20vh;
  overflow: hidden;

  h4 {
    color: #343434;
    font-size: large;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.4;
    font-weight: 700;
    margin: 0;
  }

  h5,
  h6 {
    display: none;
  }

  p {
    display: none;
  }

  p:first-of-type {
    color: var(--paragraph-color);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.3;
  }

  ul {
    display: none;
  }

  a {
    display: none;
  }

  li {
    display: none;
  }

  @media screen and (max-width: 576px) {
    max-width: 94%;
  }

  & ::-webkit-scrollbar {
    display: none;
  }

  .learn-more-article-headline-card-footer {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 5px;
    right: 10px;
    gap: 7px;

    @media screen and (max-width: 575px) {
      gap: 9px;
    }

    span {
      font-weight: 600;
      color: var(--primary-color);
      font-size: 1.7vh;

      @media screen and (max-width: 575px) {
        font-size: 3.488372vw;
      }
    }

    img {
      object-fit: contain;
      width: 20px;
      flex-shrink: 0;
    }
  }

  &[dir="rtl"] {
    .learn-more-article-headline-card-footer {
      position: absolute;
      left: 10px;
      right: unset;

      span {
        font-weight: 600;
      }
    }
  }
}