@import "../../index.scss";
.button {
  border: none;
  width: 8.5416vw;
  height: 1.4062vw;
  min-height: 48px;
  min-width: 164px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.9rem;
  -webkit-border-radius: 0.9rem;
  -moz-border-radius: 0.9rem;
  -ms-border-radius: 0.9rem;
  -o-border-radius: 0.9rem;
  font-size: 1.041666666vw;
  color: var(--white-color);
  font-weight: 600;
}
.button-primary {
  background: var(--primary-color);
}
.button-secondary {
  background: var(--secondary-color);
}
@media screen and (max-width: 1600px) {
  .button {
    min-width: calc(164px * 0.75);
    min-height: calc(48px * 0.75);
  }
}

@media screen and (max-width: 992px) {
  .button {
    font-size: 0.8rem !important;
  }
}
