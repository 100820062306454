@import "../../index.scss";

.article-headline-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--white-color);
  min-height: 404px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 0 20px rgba(196, 200, 208, 0.5);

  #cardImg {
    width: 100%;
    height: 21.759vh;
    min-height: 235px;

    @media screen and (max-width: 1366px) {
      min-height: 0;
    }

    @media screen and (min-width: 2000px) {
      height: auto;
    }
  }

  .article-headline-card-info {
    padding: 0.5rem 1.7vh;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 10rem;

    .article-headline-title {
      font-size: 1.25vw;
      font-weight: 600;
      margin-bottom: 6px;
      // word-wrap: break-word;
    }

    .article-headline-desc {
      font-size: 1.7vh;
      color: var(--paragraph-color);
      margin-bottom: 0;

      article {
        display: flex;
        flex-direction: column;
      }

      // height: 175px;
      h4 {
        color: #343434;
        font-size: large;
        font-size: 1.3rem;
        font-weight: bold;

        @media screen and (max-width: 1366px) {
          font-size: 1.7vh;
        }
      }

      h5,
      h6 {
        display: none;
      }

      p {
        display: none;
      }

      p:first-of-type {
        color: var(--paragraph-color);
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 1.3;
        font-size: 1.125rem;
        margin-bottom: 0;

        @media screen and (max-width: 1366px) {
          font-size: 0.9rem;
        }
      }

      ul {
        display: none;
      }

      a {
        display: none;
      }

      li {
        display: none;
      }

      @media (max-width: 576px) {
        height: unset;
      }
    }

    .article-headline-more-btn-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: end;
      margin: 0;
      gap: 7px;

      span {
        color: var(--primary-color);
        font-weight: 600;
        font-size: 1.7vh;
      }

      div {
        padding: 5px;
        background: var(--primary-color);
        border-radius: 100%;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        -ms-border-radius: 100%;
        -o-border-radius: 100%;
        width: 25px;
        height: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width: max-content;
          height: 170%;
        }
      }
    }

    @media screen and (min-width: 2000px) {
      padding: 2.5vh 1.7vh;
      height: 13rem;
    }
  }

  @media screen and (max-width: 1366px) {
    min-height: 0;
  }

  @media screen and (max-width: 1280px) {
    width: auto;
    min-height: auto;

    #cardImg {
      min-height: auto;
    }
  }

  @media screen and (max-width: 576px) {
    width: 90vw;

    #cardImg {
      height: 9rem;
    }

    .article-headline-title {
      font-size: 18px !important;
    }

    .article-headline-desc {
      font-size: 1.7vh !important;
    }
  }
}