@import "../../../index.scss";

.terms-of-use-menu-item-active {
  border-radius: 0px 25px 25px 0px !important;
  -webkit-border-radius: 0px 25px 25px 0px !important;
  -moz-border-radius: 0px 25px 25px 0px !important;
  -ms-border-radius: 0px 25px 25px 0px !important;
  -o-border-radius: 0px 25px 25px 0px !important;
  border-left: 4px solid var(--primary-color);
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;

  span {
    color: #343434;
  }
}

.terms-of-use-menu-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: var(--white-color);
  padding: 1vh;
  border-radius: 25px 25px 25px 25px;
  -webkit-border-radius: 25px 25px 25px 25px;
  -moz-border-radius: 25px 25px 25px 25px;
  -ms-border-radius: 25px 25px 25px 25px;
  -o-border-radius: 25px 25px 25px 25px;
  box-shadow: 0 4px 30px rgba(196, 200, 208, 0.5);
  font-size: 1.04vw;
  font-weight: 600;
  width: 20.729vw;

  span {
    font-size: 1.05vw;
    color: #343434;

    @media screen and (max-width: 850px) {
      font-size: 12px;
    }
  }

  .terms-of-use-menu-item-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.7vh;
    border-radius: 100%;
    background: var(--light-primary-color);
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    width: 2.1875vw;
    height: 3.888vh;
    min-width: 42px;
    min-height: 42px;

    img {
      min-width: 42px;
      min-height: 42px;

      @media screen and (max-width: 1600px) {
        width: 100%;
        height: 100%;
      }

      @media screen and (max-width: 575px) {
        width: 100% !important;
      }
    }

    @media screen and (max-width: 1600px) {}

    @media screen and (max-width: 1280px) {}

    @media screen and (max-width: 992px) {}

    @media screen and (max-width: 576px) {
      min-width: 42px;
      min-height: 42px;
    }
  }

  .terms-of-use-menu-item-arrow {
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-left: auto;

    img {
      width: 1.7vh;
    }
  }

  @media screen and (max-width: 1280px) {
    padding: 0.5rem;
  }

  @media screen and (max-width: 992px) {
    width: 15rem;

    span {
      font-size: 1.7vh;
    }
  }

  @media screen and (max-width: 576px) {
    width: auto;
    height: auto;
    padding: 2vw;
  }
}

[dir="rtl"] {
  .terms-of-use-menu-item {
    @media screen and (max-width: 1366px) {
      padding: 0.7vh;
    }

    @media screen and (max-width: 575px) {
      width: auto;
      height: auto;
      padding: 2vw;
    }
  }

  .terms-of-use-menu-item-active {
    border-right: 4px solid var(--primary-color);
    border-left: 0;
    border-radius: 25px 0px 0px 25px !important;
    -webkit-border-radius: 25px 0px 0px 25px !important;
    -moz-border-radius: 25px 0px 0px 25px !important;
    -ms-border-radius: 25px 0px 0px 25px !important;
    -o-border-radius: 25px 0px 0px 25px !important;
  }

  .terms-of-use-menu-item-arrow {
    margin-right: auto;
    margin-left: 0;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
  }
}