.terms-of-use-section {
  position: relative;
  height: 100vh;
  display: flex;
  width: 85vw;
  margin: 0 auto;
  padding-top: calc(144px + 3vh);
  justify-content: space-between;
  gap: 1.7vh;

  @media screen and (max-height: 568px) and (orientation: landscape) {
    min-height: 100vh;
    height: unset !important;
  }

  .terms-of-use-tabs-panel-wrapper {
    display: flex;
  }

  #terms-of-use-tabs-panel {
    display: flex;
  }

  .nav-pills .nav-link {
    padding-left: 0;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    background: none;
    // color: var(--black-color);
    font-size: 1.04vw;
    font-weight: 600;
    padding-left: 0;
  }

  .terms-of-use-content-container {
    padding-top: 3rem;

    .terms-of-use-content-title {
      p {
        font-weight: 600;
        margin-bottom: 1vh !important;
        font-size: 2vh;
        margin-bottom: 0;

        @media screen and (max-width: 1280px) {
          font-size: 14px;
        }

        @media screen and (max-width: 850px) {
          font-size: 14px;
        }

        @media screen and (max-width: 576px) {
          font-size: 14px;
        }
      }

      @media screen and (max-width: 576px) {
        padding: 3.4vh;
        padding-bottom: 0.5rem;
        display: flex;
        flex-direction: column;
        gap: 1.7vh;

        h1 {
          margin-bottom: 0;
        }
      }
    }

    @media screen and (min-width: 2000px) {
      padding-top: 6rem;
    }

    @media screen and (max-width: 1366px) {
      padding-top: 0;
    }
  }

  .terms-of-user-tab-wrapper {
    display: flex;
    gap: 2vw;
  }

  @media screen and (max-width: 576px) {
    padding-top: 122px;
    margin: 0;
    width: 100%;
    height: auto !important;

    .nav-pills {
      .terms-of-use-nav-item {
        a {
          padding: 2vw 0;
        }
      }
    }

    .terms-of-user-tab-wrapper {
      display: flex;
      gap: 3.4642vw;
    }
  }

  .tab-content {
    margin-inline-start: 10px;
  }
}

[dir="rtl"] {
  .nav-pills .nav-link {
    padding-right: 0;
  }

  .terms-of-use-tabs-panel-wrapper {
    gap: 1.7vh;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    padding-right: 0;
  }
}

.terms-of-use-ads-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.7vh;
}