@import "../../index.scss";

.footer {
  background: var(--secondary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.7vh;

  .footer-links-container {
    margin-bottom: 30px;

    .footer-list {
      display: flex;
      align-items: center;
      gap: 3rem;

      li {

        a,
        div {
          text-decoration: none;
          color: var(--white-color);
          font-weight: 600;
        }

        div {
          cursor: pointer;
        }
      }
    }
  }

  .footer-copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--white-color) !important;
    font-weight: 600;
    // font-size: 1.25vw;
    // font-size: 0.8333333333333vw;
    font-size: 1.7vh;
  }

  @media screen and (max-width: 576px) {
    .footer-links-container {
      .footer-list {
        li {
          a {
            font-size: 12px;
          }
        }
      }
    }
  }
}