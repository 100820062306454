@import "../../index.scss";

.our-app-servies-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-image: unset !important;
  position: relative;
  background-image: url("../../../public/assets/BeeCells.svg") !important;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  // padding-top: 16vh;
  gap: 1.7vh;

  @media screen and (max-height: 568px) and (orientation: landscape) {
    min-height: 100vh;
    height: unset !important;
  }

  .container {
    position: relative;
    height: 100%;
    max-width: 1440px !important;
  }

  @media screen and (max-width: 576px) {
    min-height: 100vh;
    // padding-top: calc(144px);

    background-image: url("../../../public/assets/beeCellsMobile.webp") !important;
  }
}

.our-app-servies-section.ar {
  .our-app-service-card-footer-icon-wrapper {
    left: 20px;
    transform: scaleX(-1);
  }

  .our-app-account-card-footer {
    left: 20px;
    transform: scaleX(-1);
  }

  .our-app-service-card-active,
  .our-app-account-card-active {
    transform: translateX(1vw) !important;
  }

  .our-services-tab-cards-title {
    margin-inline-start: 4vw;
  }
}

.our-app-servies-section.en {
  .our-app-service-card-footer-icon-wrapper {
    right: 20px;
  }

  .our-app-account-card-footer {
    right: 20px;
  }
}

#beesBackground {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;

  img {
    width: 100vw;
    height: 100vh;

    @media screen and (max-height: 568px) and (orientation: landscape) {
      min-height: 100vh;
      height: unset !important;
    }
  }
}