@import "../../index.scss";

.contact-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5vw;
  width: 24%;

  .contact-info-numbers {
    display: flex;
    flex-direction: column;

    a {
      margin: 0;
      font-size: 1.04166vw;
      font-weight: 600;
      text-decoration: none;
      color: var(--secondary-color);
      direction: ltr !important;
    }
  }

  .contact-info-icons {
    display: flex;
    align-items: center;
    gap: 0.90104vw;
    width: fit-content;

    .contact-icon-container {
      width: 2.21354vw;
      height: 2.21354vw;
      background: var(--secondary-color);
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        // aspect-ratio: 4/4;
        &.youtube {
            width: 70%;
            height: 70%;
          }
      }
    }
  }

  .contact-info-msg {
    width: 20.15vw;

    p {
      font-weight: 600;
      color: var(--secondary-color);
      margin-bottom: 0;
    }
  }

  .contact-info-app-cards-container {
    display: flex;
    gap: 0.6041vw;

    @media screen and (max-width: 1366px) {
      flex-direction: column;
    }

    @media screen and (max-width: 1024px) {
      flex-direction: row;
    }
  }

  @media screen and (max-width: 1366px) {
    gap: 0.8rem;
  }

  @media screen and (max-width: 1024px) {
    width: 90%;
  }

  @media screen and (max-width: $Tablets-Breakpoint) {
    .contact-info-app-cards-container {
      flex-direction: column;
    }
  }

  @media screen and (max-width: $Mobile-Breakpoint) {
    height: fit-content;
    width: unset;

    .title {
      font-size: 3.826086vw;
      margin-bottom: 0;
      white-space: nowrap;
    }

    .contact-info-numbers {
      line-height: 25px;
      gap: 0.7vw;
    }

    .contact-info-numbers a {
      font-size: 3.2558139vw;
    }

    .contact-info-icons {
      gap: 3.2558vw;

      .contact-icon-container { 
        width: 7.82093vw;
        height: 7.82093vw;

        img {
          width: 90%;
          height: 90%;
          object-fit: contain;
          // aspect-ratio: 4/3;
            &.youtube {
                width: 70%;
                height: 70%;
              }
        }

      }
    }
  }

  @media screen and (max-width: $Extra-Small-Mobile-Breakpoint) {
    .title {
      font-size: 2vh;
    }
  }
}

[dir="rtl"] {
  .contact-info-numbers {
    text-align: end;
  }
}