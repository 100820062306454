@import "../../index.scss";

.our-app-service-card {
  display: flex;
  flex-direction: column;
  background: transparent;
  align-self: center;
  cursor: pointer;

  .our-app-service-card-header {
    z-index: 1;
    display: flex;
    flex-direction: column;
    margin-top: 1vh;
    gap: 1.5vh;

    @media screen and (max-width: 768px) {
      display: flex;
      align-items: center;
      gap: 8vw;
      flex-direction: row;

      .our-app-service-card-title-container {
        margin-bottom: 0.7rem;

        P {
          color: var(--secondary-color);
          font-size: 1.7vh;
        }
      }
    }
  }

  .our-app-service-card-body {
    display: flex;
    flex-direction: column;
  }

  .our-app-service-card-icon-container {
    width: 3.5416vw;
    height: 3.5416vw;
    min-width: 58px;
    min-height: 58px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    background: var(--secondary-color);

    img {
      // aspect-ratio: 16/9;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }

    @media screen and (max-width: 1600px) {
      width: calc(3.5416vw * 0.75);
      height: calc(3.5416vw * 0.75);
    }

    @media screen and (max-width: 1280px) {
      width: calc(3.5416vw * 0.625);
      height: calc(6.296vh * 0.625);
    }

    @media screen and (max-width: 992px) {
      width: calc(3.5416vw * 0.516);
      height: calc(6.296vh * 0.516);
    }
  }

  .our-app-service-card-desc-container {
    .our-app-service-card-desc {
      font-weight: 600;
      font-size: 0.9375vw;
      overflow: auto;

      @media screen and (max-width: 992px) {
        font-size: 1.7vh;
      }
    }
  }

  .our-app-service-card-title-container {
    margin-bottom: 0.7rem;

    p {
      color: var(--secondary-color);
      font-size: 1.7vh;
      font-weight: 600;

      @media screen and (max-width: 992px) {
        font-size: 1.04vw;
        margin-bottom: 6px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    align-self: auto;
    padding-inline-start: 1.7vh;
  }

  @media screen and (max-width: 575px) {
    padding-inline-start: 0rem;
  }
}

.our-app-service-card-active {
  top: 15%;
  width: 23vw;
  height: 25vw;
  min-width: 362px;
  display: flex;
  flex-direction: column;
  background: var(--white-color);
  cursor: pointer;
  padding: 1.7vh;
  padding-bottom: 2.5vh;
  position: relative;
  border-radius: 70px;
  -webkit-border-radius: 70px;
  -moz-border-radius: 70px;
  -ms-border-radius: 70px;
  -o-border-radius: 70px;
  box-shadow: 0px 0px 40px rgba(196, 200, 208, 0.5);
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  flex-grow: 1;

  .our-app-service-card-header {
    margin-top: 1vh;

    @media screen and (max-width: 768px) {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }

  .our-app-service-card-body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .our-app-service-card-title-container {
    margin-bottom: 0.7rem;

    p {
      color: var(--secondary-color);
      font-size: 1.26rem;
      font-weight: 600;
      color: var(--primary-color);
      margin: 0;

      @media screen and (max-width: 992px) {
        font-size: 1.04vw;
        margin-bottom: 6px;
      }

      @media screen and (max-width: 576px) {
        font-size: 18px;
      }
    }
  }

  .our-app-service-card-desc-container {
    .our-app-service-card-desc {
      font-weight: 600;
      font-size: 0.9375vw;
      // line-height: 1.2;
      margin: 0;
      overflow: auto;

      @media screen and (max-width: 992px) {
        font-size: 1.7vh;
      }

      @media screen and (max-width: 576px) {
        font-size: 14px;
      }
    }
  }

  .our-app-service-card-icon-container {
    width: 5.10416vw !important;
    height: 5.10416vw !important;
    min-width: 58px;
    min-height: 58px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    background: var(--light-blue-color);
    margin-bottom: 2vh;

    img {
      // aspect-ratio: 16/9;
      object-fit: contain;
      width: 100%;
      height: 100%;
      // width: 2.195vw;
      // height: 5.07777vh;
      // min-height: 54.48px;
      // min-width: 42.15px;
    }

    @media screen and (max-width: 1600px) {
      width: calc(5.10416vw * 0.75);
      height: calc(9.07407vh * 0.75);
    }

    @media screen and (max-width: 1280px) {
      margin-bottom: 1vh;
      width: calc(5.10416vw * 0.625);
      height: calc(9.07407vh * 0.625);
    }

    @media screen and (max-width: 992px) {
      width: calc(5.10416vw * 0.516);
      height: calc(9.07407vh * 0.516);
    }

    @media screen and (max-width: 576px) {
      min-width: 50px;
      min-height: 50px;
      width: 14.54vw !important;
      height: 14.54vw !important;
      max-width: 70px;
      max-height: 70px;
    }
  }

  @media screen and (max-width: 1366px) {
    min-width: 20rem;
    min-height: 25rem;
  }

  @media screen and (max-width: 1280px) {
    min-width: 25vw;
    min-height: 0;
    height: 21rem;
    top: 15%;
  }

  @media screen and (max-width: 992px) {
    min-width: 30vh;
  }

  @media screen and (max-width: 768px) {
    border-radius: 25px;
    position: relative;
    min-width: 90vw;
    flex-direction: row;
    transition: none;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    min-height: 10rem;
    height: 10rem;
    top: 0;
  }

  @media screen and (max-width: 576px) {
    border-radius: 25px;
    position: relative;
    min-width: 85vw;
    flex-direction: row;
    transition: none;
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
  }
}

.our-app-service-card-active-mobile-wrapper {
  display: flex;
  gap: 5.34vw;

  .our-app-service-card-icon-container {
    width: 6rem !important;
    height: 6rem !important;
  }

  .our-app-service-card-info {
    display: flex;
    overflow: auto;
    flex-direction: column;
  }
}