@import "../../index.scss";

.about-ads-cards-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.about-us-section {
  height: 100vh;
  position: relative;

  @media screen and (max-height: 568px) and (orientation: landscape) {
    min-height: 100vh;
    height: unset !important;
  }

  .about-us-section-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-top: calc(144px + 3vh);
    width: 85.4vw;
    margin: 0 auto;

    .about-us-section-title {
      margin-bottom: 2.6041vw;
    }

    .about-us-section-list {
      display: flex;
      gap: 0.833vw;
      height: 65%;

      .tab-content {
        margin-left: auto;
      }

      .about-tabs-list {
        gap: 2.34vw;
        z-index: 250;
        width: fit-content;

        .nav-link {
          font-weight: 600;
          color: var(--secondary-color) !important;
          font-size: 1.3vw;
          padding: 0;
        }
      }

      .nav-pills .nav-link.active,
      .nav-pills .show>.nav-link {
        background: transparent !important;
        border: none !important;
        position: relative;
        padding: 8px 0 !important;
        font-weight: 600;
        color: var(--primary-color) !important;
        font-size: 1.302vw;

        &::after {
          content: " ";
          position: absolute;
          width: 2.2rem;
          height: 6px;
          background: var(--primary-color) !important;
          bottom: 0px;
          left: 0;
          border-radius: 25px;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
        }
      }

      @media screen and (max-width: 575px) {
        justify-content: space-around !important;
      }
    }
  }

  &[dir="rtl"] {
    .tab-content {
      margin-left: 0 !important;
      margin-right: auto;
    }

    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link {
      &::after {
        content: " ";
        position: absolute;
        width: 2.2rem !important;
        height: 6px;
        background: var(--primary-color) !important;
        bottom: -8px;
        right: 0;
        border-radius: 25px;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
      }
    }

    .about-us-section-list {
      .tab-content {
        margin-left: 0 !important;
        margin-right: auto !important;
      }
    }
  }

  @media screen and (max-width: 1449px) {
    .about-us-section-title {
      margin-bottom: 0vw !important;
    }

    .about-us-section-title h1 {
      font-size: 3.1034vw;
    }

    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link {
      font-size: 1.6551vw !important;
    }

    .about-us-section-container {
      padding-top: calc(144px + 4.344vw);

      .about-us-section-list {
        height: 32.8965vw;

        .about-tabs-list {
          .nav-link {
            font-size: 1.37931vw;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1366px) {
    .about-us-section-container {
      padding-top: 10rem;
    }
  }

  @media screen and (max-width: 1024px) {
    .about-us-section-title h1 {
      font-size: 4vh;
    }

    .about-us-section-title {
      h1 {
        margin-bottom: 0;
      }
    }

    .about-us-section-container {
      padding-top: 12rem;
      width: 85.4vw;
      flex-direction: column;

      .nav-pills .nav-link.active,
      .nav-pills .show>.nav-link {
        font-size: 3.4vh !important;
      }

      .about-us-section-list {
        height: 100%;
        flex-direction: column;
        justify-content: space-evenly;

        .about-tabs-list {
          .nav-link {
            font-size: 2.5vh;
          }
        }

        .tab-content {
          margin-right: 0;
          height: 50%;
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 992px) {
    .about-us-section-container {
      height: 100vh;
      padding-top: calc(144px + 8.2vw);

      .about-us-section-list {
        .tab-content {
          margin-right: 0;
        }

        .about-tabs-list {
          .nav-link {
            font-size: 1.678657vw;
          }
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    height: 100%;

    .about-us-section-container {
      width: 100vw;
      padding: 0 3.826vw;
      padding-top: calc(113px + 10.465116vw);
      justify-content: start;

      .about-us-section-list {
        justify-content: space-around !important;

        .tab-content {
          margin: 0;
        }

        .about-tabs-list {
          gap: 12px;

          .nav-link {
            padding: 0;
            font-size: 2vh !important;

            a {
              padding: 0;
            }
          }
        }
      }
    }

    .about-us-section-title h1 {
      font-size: 3.4vh;
      margin-bottom: 0vw !important;
    }

    .about-us-section-container {
      .about-us-section-list {
        flex-direction: column;
        gap: 1.3rem;
        height: 100%;
        justify-content: center;
        width: 90vw;
        margin: 0 auto;

        .nav-pills .nav-link.active,
        .nav-pills .show>.nav-link {
          font-size: 2.5vh !important;

          &::after {
            bottom: 0px;
            width: 2vh !important;
          }
        }

        .nav-pills .show>.nav-link {
          font-size: 14px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .about-us-section-container {
      .about-us-section-list {
        .about-tabs-list {
          .nav-link {
            font-size: 18px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 575px) {
    height: 100vh;
  }
}