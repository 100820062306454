@import "../../index.scss";

.learn-more-ads-warpper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 1.3541vw;

  @media screen and (min-width: 2000px) {
    gap: 0.5rem;
  }
}

.learn-more-articles-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  z-index: 1;
  padding-top: calc(115px + 3vh);
  gap: 8.0208vw;

  @media screen and (max-height: 568px) {
    min-height: 100vh;
    height: unset !important;
    padding-top: calc(115px + 9vh);
  }


  .pagination-section {
    margin: 0 !important;
  }

  .container {
    height: 100%;
  }

  .learn-more-articles-section-cards-container {
    width: 65vw;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: repeat(2, minmax(150px, 1fr));
    justify-items: center;
    gap: 1.7vh;
    column-gap: 1.7vh;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    padding: 1.8vh !important;
    overflow: auto;
    height: 62vh;

    .learn-more-article-card-wrapper {
      border-radius: 30px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // height: 18.185vh;
      width: 100%;
      height: 100%;
    }

    @media screen and (min-width: 2000px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 1300px) {
    width: 70vw;
    height: 65vh;
    }

    @media screen and (max-width: 992px) {
      width: 90vw;
      grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));
    }

    @media screen and (max-width: 768px) {
      align-items: center;
      height: 57vh;
      padding: 0rem !important;
      padding: 2.5vh !important;
      padding-inline-end: 0.5rem;
      overflow: hidden;
    }

    @media screen and (max-width: 750px) {
      align-items: center;
      width: 92vw;
    }

    @media screen and (max-width: 576px) {
      align-items: center;
      width: unset;
      order: 2;
    }
  }

  .learn-more-articles-section-cards-container::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  .readMode-container {
    display: flex;
    margin-top: 4vh;
    justify-content: end;
    scrollbar-width: none;
    gap: 4vw;
    width: 85.4vw;

    .learn-more-articles-section-cards-container {
      display: flex;
      flex-direction: column;
      gap: 0px;
      height: 77vh;
      padding: 1.7vh;
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2 ease-in-out;
      scrollbar-width: none;
      width: 35vw;

      & :-webkit-scrollbar {
        display: none;
      }

      // @media screen and (max-width: 900px) {
      //   width: auto;
      // }
      @media screen and (max-width: 576px) {
        width: 100%;
        padding-top: 4px;
        height: auto;

        .swiper {
          .swiper-wrapper {
            width: 90vw;
          }
        }
      }
    }

    @media screen and (max-width: 1280px) {
      width: 85vw;
      gap: 2vw;
    }

    @media screen and (max-width: 992px) {
      justify-content: space-between;
    }

    @media screen and (max-width: 750px) {
      flex-direction: column;
      width: 100vw;
      margin-top: 3vh;
    }
  }

  @media screen and (max-width: 1600px) {
    gap: 5.0208vw;
  }

  @media screen and (max-width: 750px) {
    padding-top: 12rem;
    height: auto;
  }

  @media screen and (max-width: 576px) {
    padding: 0;
    padding-top: 0px;
    align-items: start;
    padding-top: calc(120px + 4rem);
    height: 100%;

    .title {
      margin: 0;
    }
  }
}

.learn-more-articles-section .readMode-container .learn-more-articles-section-cards-container {
  color: var(--paragraph-color) !important;
  margin: 0;

  .learn-more-article-headline-card-container {
    max-width: unset !important;
    flex-shrink: 0;
    height: 100%;
    width: 100%;

    @media screen and (max-width: 750px) {
      min-height: unset !important;
      min-width: 20rem;
    }

    @media screen and (max-width: 400px) {
      min-height: unset !important;
      min-width: 17rem;
    }
  }

  & ::-webkit-scrollbar {
    display: none;
  }
}

.learn-more-article-content-cards-container {
  width: 48.854vw;
  height: 70.7407vh;
  min-width: 938px;

  @media screen and (max-width: 576px) {
    min-width: 100%;
    min-height: auto;
    height: 100vw;
  }

  .learn-more-article-card-wrapper {
    border-radius: 30px;
    height: 70.7407vh;

    @media screen and (max-width: 576px) {
      width: 95vw;
      margin: 0 auto;
      min-height: 433px;
    }
  }
}

.learn-more-articles-section-cards-container.hihi {
  position: relative;
  padding: 0 !important;
  overflow: visible;
  height: 72.4vh !important;

  .scrolling-content {
    min-width: 29vw !important;
    display: flex;
    flex-direction: column;
    gap: 0rem;
    align-items: center;
    overflow: auto;
    background: transparent;
    padding: 1.7vh;
    justify-content: center;
    align-items: center;
    height: 60vh;
    flex-grow: 1;
    gap: 1.7vh;

    @media screen and (max-width: 992px) {
      min-width: 35vw !important;
    }
  }

  @media (min-width: 992px) {
    margin-inline-start: 5vw;
  }
}

.learn-more-articles-section-cards-container.ho {
  gap: 0 !important;

  .learn-more-article-content-cards-container {
    height: 30vh;
    min-height: unset !important;
  }

  .title-with-pagination {
    justify-content: space-between;
    width: 90vw;
    display: flex;

    .centered-title {
      text-align: center;
      font-size: 3vw;
      font-weight: 600;
      margin-bottom: 0;

      @media (max-width: 920px) {
        font-size: 1.4rem;
        text-align: start;
      }

      @media (max-width: 575px) {
        font-size: 22px;
        color: var(--secondary-color);
      }
    }

    .pagination-arrows {
      display: flex;
      gap: 2vw;
      margin-inline-end: 0vw;

      .pagination-arrow-left {
        cursor: pointer;
        width: 25px;
        height: 25px;
        border-radius: 100%;
        background-color: var(--secondary-color);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 100%;
          width: max-content;
          object-fit: contain;
        }
      }

      .pagination-arrow-left.disabled {
        background-color: var(--tertiary-color);
      }

      .pagination-arrow-right {
        cursor: pointer;
        width: 25px;
        height: 25px;
        border-radius: 100%;
        background-color: var(--secondary-color);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 100%;
          object-fit: contain;
        }
      }

      .pagination-arrow-right.disabled {
        background-color: var(--tertiary-color);
      }
    }
  }

  .horizontal-scrolling {
    display: flex;
    overflow-x: auto;
    padding: 1.7vh;
    width: 97vw;
    gap: 0.8rem;
    justify-content: flex-start;
  }

  .horizontal-scrolling+.pagination-section {
    margin: 0;
    position: absolute;
    top: 23vh;
    right: 6vw;
    flex-direction: column;
    gap: 1.7vh;

    @media (min-width: 578px) and (max-width: 750px) {
      top: 22vw;
    }
  }

  @media (min-width: 576px) and (max-width: 750px) {
    height: 35vh !important;
    order: 2;
    justify-content: space-between;
  }
}

.learn-more-article-content-cards-container.hihi {
  box-shadow: 0 0 20px 2px rgba(85, 103, 133, 0.3);
  min-width: unset !important;
  border-radius: 30px;
  overflow: hidden;
  width: 45vw;
  display: flex;
  flex-direction: column;

  .learn-more-article-card-wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  @media (max-width: 750px) {
    height: 100vw !important;
    width: 92.46vw !important;
    margin: auto;
  }

  @media (max-width: 575px) {
    height: 100% !important;
  }

  @media screen and (max-width: 750px) and (min-width: 578px) {
    height: 70vw !important;
  }
}

.learn-more-articles-section-cards-container.hi {
  .learn-more-article-card-wrapper {
    margin: 0 !important;
  }

  @media screen and (max-width: 576px) {
    width: 100vw;
    padding-inline: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6vw;
    overflow: visible;
    height: 100%;
  }
}

.learn-more-articles-section-title {
  padding-inline: 1.7vh;
  width: 100%;

  @media (max-width: 576px) {
    padding-inline: 5vw;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 4vh;
    margin-top: 24px;
    justify-content: space-between;

    .pagination-section {
      align-self: flex-end;

      img {
        cursor: pointer;
      }
    }
  }
}

.learn-more-articles-section-title.mb-3 {
  margin-inline-start: 2vw;

  @media screen and (max-width: 575px) {
    margin-inline-start: 0;
  }
}

[dir="rtl"] {
  .horizontal-scrolling+.pagination-section {
    left: 6vw;
    right: auto !important;
  }

  .pagination-arrow-left {
    img {
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
    }
  }

  .pagination-arrow-right {
    img {
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
    }
  }
}

.scrolling-content.hoho {
  .learn-more-article-headline-card-container {

    h4,
    p {
      margin: 0 !important;
      line-height: 1.2;
    }
  }
}