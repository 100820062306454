@import "../../index.scss";

.swiper-slide-shadow-right {
  background-image: linear-gradient(to right,
      rgba(0, 0, 0, 0.08),
      rgba(0, 0, 0, 0)) !important;
  //height: 100% !important;
  //width: 27.916vw !important;
  border-radius: 24px !important;
  -webkit-border-radius: 24px !important;
  -moz-border-radius: 24px !important;
  -ms-border-radius: 24px !important;
  -o-border-radius: 24px !important;
  background-attachment: fixed;
}

.swiper-slide-shadow-left {
  //height: 100% !important;
  //width: 27.916vw !important;
  background-image: linear-gradient(to left,
      rgba(0, 0, 0, 0.08),
      rgba(0, 0, 0, 0)) !important;
  border-radius: 24px !important;
  -webkit-border-radius: 24px !important;
  -moz-border-radius: 24px !important;
  -ms-border-radius: 24px !important;
  -o-border-radius: 24px !important;
  background-attachment: fixed;
}

.swiper-slide-prev {
  transform: translate3d(0px, 0px, -100px) rotateX(0deg) rotateY(0deg) scale(0.95) !important;
  -webkit-transform: translate3d(0px, 0px, -100px) rotateX(0deg) rotateY(0deg) scale(0.95) !important;
  -moz-transform: translate3d(0px, 0px, -100px) rotateX(0deg) rotateY(0deg) scale(0.95) !important;
  -ms-transform: translate3d(0px, 0px, -100px) rotateX(0deg) rotateY(0deg) scale(0.95) !important;
  -o-transform: translate3d(0px, 0px, -100px) rotateX(0deg) rotateY(0deg) scale(0.95) !important;
}

.swiper-slide-next {
  transform: translate3d(0px, 0px, -100px) rotateX(0deg) rotateY(0deg) scale(0.95) !important;
  -webkit-transform: translate3d(0px, 0px, -100px) rotateX(0deg) rotateY(0deg) scale(0.95) !important;
  -moz-transform: translate3d(0px, 0px, -100px) rotateX(0deg) rotateY(0deg) scale(0.95) !important;
  -ms-transform: translate3d(0px, 0px, -100px) rotateX(0deg) rotateY(0deg) scale(0.95) !important;
  -o-transform: translate3d(0px, 0px, -100px) rotateX(0deg) rotateY(0deg) scale(0.95) !important;
}

.home-articles {
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;

  @media screen and (max-height: 568px) and (orientation: landscape) {
    height: unset;
    min-height: 100vh;
  }

  .home-articles-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 85.4vw;
    transform: translateY(10vh);
    -webkit-transform: translateY(10vh);
    -moz-transform: translateY(10vh);
    -ms-transform: translateY(10vh);
    -o-transform: translateY(10vh);

    .swiper-wrapper {
      padding: 1.7vh 0 2vw 0;
    }

    .home-articles-title {
      width: 77vw;
      margin: 0 auto;

      @media screen and (max-width: 576px) {
        width: 90vw;

        h1 {
          margin-bottom: 0;
        }
      }
    }

    @media screen and (min-width: 2000px) {
      transform: translateY(4vh);
      -webkit-transform: translateY(4vh);
      -moz-transform: translateY(4vh);
      -ms-transform: translateY(4vh);
      -o-transform: translateY(4vh);
    }

    @media screen and (min-width: 1590px) {
      width: 91vw;
    }

    @media screen and (max-width: 1600px) {
      transform: translateY(4vh);
      -webkit-transform: translateY(4vh);
      -moz-transform: translateY(4vh);
      -ms-transform: translateY(4vh);
      -o-transform: translateY(4vh);
    }

    @media screen and (max-width: 576px) {
      justify-content: start;
      width: 92vw;
      margin: 0 auto;
      gap: 0;
      transform: translateY(0vh);
      -webkit-transform: translateY(0vh);
      -moz-transform: translateY(0vh);
      -ms-transform: translateY(0vh);
      -o-transform: translateY(0vh);

      .swiper-container {
        height: 59vh !important;

        .swiper {
          height: 100% !important;
        }

        .swiper-slide {
          width: 90vw !important;
          height: 12.7rem !important;
          opacity: 1;
        }

        .swiper-slide-prev {
          opacity: 1;
        }

        .swiper-slide-active,
        .swiper-slide-next {
          opacity: 1;
        }

        .swiper-slide-shadow-right {
          background-image: linear-gradient(to bottom,
              rgba(196, 200, 208, 0.5),
              rgba(196, 200, 208, 0.5)) !important;
          border-radius: 24px !important;
          -webkit-border-radius: 24px !important;
          -moz-border-radius: 24px !important;
          -ms-border-radius: 24px !important;
          -o-border-radius: 24px !important;
          background-attachment: fixed;
        }

        .swiper-slide-shadow-left {
          background-image: linear-gradient(to top,
              rgba(196, 200, 208, 0.5),
              rgba(196, 200, 208, 0.5)) !important;
          border-radius: 24px !important;
          -webkit-border-radius: 24px !important;
          -moz-border-radius: 24px !important;
          -ms-border-radius: 24px !important;
          -o-border-radius: 24px !important;
          background-attachment: fixed;
        }
      }
    }
  }

  .swiper-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
  .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    transform: translate(-4vw, 0.5vh);

    @media screen and (max-height: 568px) and (orientation: landscape) {
      transform: translate(-4vw, 1.5vh);
    }

    .swiper-pagination-bullet-active {
      // width: 60px !important;
      border-radius: 10px;
    }

    .swiper-pagination-bullet {
      background-color: #3b86ff;
      width: 12px !important;
      height: 12px;
    }
  }

  @media screen and (max-width: 1024px) {
    height: auto;
  }

  @media screen and (max-width: 992px) {
    height: auto;
  }

  @media screen and (max-width: 576px) {
    padding-top: 0;
    height: 65vh;
  }
}

.App.ar {

  .swiper-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
  .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    transform: translate(-5.5vw, 0.5vh);
  }
}

.swiper-pagination {
  // width: 5.208333333333vw !important;
  width: 9vw !important;
}

.swiper-3d {
  perspective: 1500px !important;
}

.swiper-pagination-bullet-active {
  width: 2vw !important;
}