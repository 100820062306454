@import "../../index.scss";

.contact-form {
  display: flex;
  justify-content: space-between;
  width: 47.1vw;
  height: 48vh;
  padding: 1.8rem;
  border-radius: 26px;
  -webkit-border-radius: 26px;
  -moz-border-radius: 26px;
  -ms-border-radius: 26px;
  -o-border-radius: 26px;
  position: relative;
  overflow: hidden;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../../public/assets/contactUs.webp");
  box-shadow: 0 0 0 rgba($color: #000000, $alpha: 0.16);

  .contact-form-inputs {
    display: flex;
    flex-direction: column;
    gap: 1.7vh;
    height: 100%;
  }

  .contact-form-container {
    width: 50%;
    min-width: 17rem;
    background: transparent;
    display: flex;
    flex-direction: column;

    .form {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      gap: 0.729166vw;

      .textarea-wrapper {
        background: white;
        padding-inline-start: 0;
        padding-inline-end: 0.7rem;
        padding-top: 1.7vh;
        padding-bottom: 0.5rem;
        height: 100%;
        border-radius: 15px;
        width: 100%;
        overflow: hidden;
      }

      input {
        background: var(--white-color);
        padding: 1.7vh;
        width: 100%;
        height: 3.5rem;
        border-radius: 15px;
        border: none;

        &:focus {
          border: none;
          box-shadow: none;
        }

        &:active {
          border: none;
        }
      }

      input::placeholder {
        color: #778289;
      }

      textarea {
        resize: none;
        border-radius: 15px;
        border: none;

        &:focus {
          border: none;
          box-shadow: none;
        }

        &:active {
          border: none;
        }

        &::-webkit-scrollbar {
          width: 5px !important;
          height: 10vh !important;
          border-radius: 25px !important;
          -webkit-border-radius: 25px !important;
          -moz-border-radius: 25px !important;
          -ms-border-radius: 25px !important;
          -o-border-radius: 25px !important;
          position: relative;

          &::after {
            content: " " !important;
            position: absolute !important;
            top: 50% !important;
            right: 0 !important;
            transform: translate(-50%, -50%) !important;
            height: 80% !important;
            width: 10px !important;
            background: transparent !important;
            pointer-events: none !important;
            -webkit-transform: translate(-50%, -50%) !important;
            -moz-transform: translate(-50%, -50%) !important;
            -ms-transform: translate(-50%, -50%) !important;
            -o-transform: translate(-50%, -50%) !important;
          }
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: transparent !important;
          box-shadow: inset 0 0 5px #dddddd !important;
          border-radius: 4px !important;
          border-left: 1.5px solid transparent !important;
          border-right: 1.5px solid transparent !important;
          border-radius: 25px !important;
          -webkit-border-radius: 25px !important;
          -moz-border-radius: 25px !important;
          -ms-border-radius: 25px !important;
          -o-border-radius: 25px !important;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #d0dbe5 !important;
          border-radius: 4px !important;
          width: 5px !important;
          border-radius: 25px !important;
          -webkit-border-radius: 25px !important;
          -moz-border-radius: 25px !important;
          -ms-border-radius: 25px !important;
          -o-border-radius: 25px !important;
        }
      }

      textarea::placeholder {
        color: #778289;
      }

      button {
        align-self: end;
        font-size: 2vh;
        width: 8.54166666vw;
        height: 3.5rem;

        @media screen and (max-width: 1280px) {
          font-size: 0.9rem;
        }

        @media screen and (max-width: 575px) {
          width: 6rem;
        }
      }
    }
  }

  .contact-form-img {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media screen and (max-width: $Medium-Labtop-Breakpoint) {
    height: auto;
  }

  @media screen and (max-width: 1024px) {
    width: 90%;
    background-size: contain;
    background-color: #f5f7fb;

    .contact-form-container {
      width: 70%;

      .form {
        gap: 0;
        justify-content: space-between;
      }
    }
  }

  @media screen and (max-width: $Tablets-Breakpoint) {
    height: 100%;
  }

  @media screen and (max-width: $Mobile-Breakpoint) {
    height: 100%;
    width: 100%;
    padding: 2.6086vw 3.1304347vw;
    border-radius: 10px;
    overflow: visible;
    background-position-x: 45%;

    .contact-form-inputs {
      gap: 10px;
    }

    .contact-form-container {
      width: 51.093023vw;

      .form {
        background: transparent;
        width: 100%;
        gap: 1.7vh;

        input,
        textarea {
          border: none;
          outline: none;
          padding: 8px 1.7vh;
          width: 50.093023vw;
          height: 6.469565217391304vw;
          border-radius: 10px;
        }

        input {
          height: 8.604651vw;
          width: 100%;
        }

        textarea {
          height: 20vw;

          &::-webkit-scrollbar {
            width: 5px !important;
            height: 10vh !important;
            border-radius: 25px !important;
            -webkit-border-radius: 25px !important;
            -moz-border-radius: 25px !important;
            -ms-border-radius: 25px !important;
            -o-border-radius: 25px !important;
            position: relative;

            &::after {
              content: " " !important;
              position: absolute !important;
              top: 50% !important;
              right: 0 !important;
              transform: translate(-50%, -50%) !important;
              height: 80% !important;
              width: 10px !important;
              background: transparent !important;
              pointer-events: none !important;
              -webkit-transform: translate(-50%, -50%) !important;
              -moz-transform: translate(-50%, -50%) !important;
              -ms-transform: translate(-50%, -50%) !important;
              -o-transform: translate(-50%, -50%) !important;
            }
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: transparent !important;
            box-shadow: inset 0 0 5px #dddddd !important;
            border-radius: 4px !important;
            border-left: 1.5px solid transparent !important;
            border-right: 1.5px solid transparent !important;
            border-radius: 25px !important;
            -webkit-border-radius: 25px !important;
            -moz-border-radius: 25px !important;
            -ms-border-radius: 25px !important;
            -o-border-radius: 25px !important;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #d0dbe5 !important;
            border-radius: 4px !important;
            width: 5px !important;
            border-radius: 25px !important;
            -webkit-border-radius: 25px !important;
            -moz-border-radius: 25px !important;
            -ms-border-radius: 25px !important;
            -o-border-radius: 25px !important;
          }
        }

        button {
          align-self: end;
          font-weight: 600;
          font-size: 12px;
          margin-top: auto;
          height: 6.7391vw;
          border-radius: 10px;
          min-height: 33px !important;
        }
      }
    }
  }
}

[dir="rtl"] {
  .contact-form {
    background-image: url("../../../public/assets/contactUsFlipped.webp");

    @media (max-width: $Mobile-Breakpoint) {
      background-position-x: 60%;
    }
  }
}