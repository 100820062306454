@import "../../index.scss";

.header-sticky {
  position: fixed;
  top: 30px;
  width: 100%;
}

.header {
  position: fixed;
  left: 0;
  right: 0;
  width: 85.4vw;
  // height: 16vh;
  min-height: 120px !important;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2.5vh;
  padding: 0.9375vw 1.09375vw;
  margin-top: 2vh;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 1.7vh;
  -webkit-border-radius: 1.7vh;
  -moz-border-radius: 1.7vh;
  -ms-border-radius: 1.7vh;
  -o-border-radius: 1.7vh;
  background: var(--white-color);
  z-index: 1111;

  .header-first-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo-container {
      img {
        width: 9.0104vw;
        height: fit-content;
      }
    }

    .first-row-selects {
      display: flex;
      gap: 6px;
    }
  }

  .header-second-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-menu-container {
      display: flex;
      gap: 1vh;
      align-items: center;

      .header-menu-list {
        display: flex;
        align-items: center;
        gap: 2.5vh;


        @media screen and (max-width: 1060px) {
          gap: 1.5vw;
        }

        @media screen and (max-width: 900px) {
          display: none;
        }

        .active-link {
 
          a {
            font-weight: 600;
            position: relative;
            color: var(--primary-color) !important;

            &::after {
              content: "";
              position: absolute;
              width: 3.4vh;
              height: 5px;
              background: var(--primary-color);
              left: 0;
              top: 1.8rem;
              border-radius: 1.7vh;
              -webkit-border-radius: 1.7vh;
              -moz-border-radius: 1.7vh;
              -ms-border-radius: 1.7vh;
              -o-border-radius: 1.7vh;
            }
          }
        }

        li {
          a {
            text-decoration: none;
            text-transform: capitalize;
            font-weight: 600;
            color: var(--secondary-color) !important;

            @media screen and (min-width: 1600px) {
              font-size: 2.5vh !important;
            }

            @media screen and (max-width: 1366px) {
              font-size: 1.7vh;
            }

            @media screen and (max-width: 992px) {
              font-size: 12px;
            }
          }
        }
      }

      @media screen and (max-width: 1200px) {
        .header-responsive-menu-container {
          img {
            width: 50px;
          }

          .header-responsive-menu {
            height: 100%;
            width: 0;
            position: fixed;
            z-index: 1;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.9);
            overflow-x: hidden;
            transition: 0.5s;
            padding-top: 60px;

            ul {
              display: block;

              a {
                padding: 8px 8px 8px 32px;
                text-decoration: none;
                font-size: 25px;
                display: block;
                transition: 0.3s;

                &::after {
                  display: none;
                }
              }
            }
          }

          .header-responsive-menu-hidden {
            width: 0px;
          }

          .header-responsive-menu-show {
            width: 250px;
          }
        }

        .header-responsive-menu-container-close-btn {
          position: absolute;
          z-index: 2;
          top: 1.7vh;
          left: 0rem;

          img {
            width: 32px;
          }
        }
      }
    }

    .header-btns-container {
      display: flex;
      gap: 1.7vh;

      @media screen and (max-width: 1000px) {
        gap: 0.5rem;
      }

      button {
        font-size: 0.937vw;

        @media screen and (max-width: 900px) {
          font-size: 0.8rem;
        }

        @media screen and (max-width: 575px) {
          min-width: 87px;
        }

        @media screen and (max-width: 575px) {
          min-width: 75px !important;
          width: 75px !important;
        }
      }
    }
  }

  @media screen and (max-width: 1366px) {}

  @media screen and (min-width: 1366px) {
    width: 90vw;
  }

  @media screen and (min-width: 1200px) {
    width: 90vw;

    .header-responsive-menu-container {
      display: none;
    }
  }

  @media screen and (max-width: 1024px) {
    gap: 0.5rem;
  }

  @media screen and (max-width: 900px) {
    min-width: calc(430px * $Mobile-Size-Ratio);
    width: 95vw;
    min-height: calc(139px * $Mobile-Size-Ratio);
    margin: 0 auto;
    padding: 5vw;
    
    top: 1.7vh;
    gap: 1.7vh;

    .header-second-row,
    .header-first-row {
      gap: 0px;
      overflow: hidden;
      padding: 0;
    }

    .header-first-row {
      padding: 0 !important;
      align-items: flex-start;
      overflow: visible;

      .logo-container {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 105px;
          height: 33px;
        }
      }
    }

    .header-second-row .header-menu-container .header-responsive-menu-container img {
      width: 18px;
    }

    .header-second-row {
      gap: 0px;
      justify-content: flex-start;
    }

    .header-search-container {
      width: fit-content;

      form {
        width: 136px;
        position: relative;
        font-size: 12px;

        img {
          position: absolute;
          z-index: 1;
          left: -1px;
          width: 18.88px;
          height: 18.88px;
        }

        input {
          width: 136px;
          text-align: center;
          font-size: 12px;

          @media screen and (max-width: 810px) {
            left: 3.4vh;
          }

          &::placeholder {
            font-size: 12px;
          }
        }
      }

      @media screen and (max-width: 900px) {
        overflow: hidden;
        border-radius: 25px;
        -webkit-border-radius: 25px;
        -moz-border-radius: 25px;
        -ms-border-radius: 25px;
        -o-border-radius: 25px;
      }
    }

    .header-btns-container {
      gap: 4px !important;
      margin-left: auto;

      button {
        width: 87px;
        height: 40px;
        font-size: 12px;
      }
    }

    #responsiveIcon {
      width: 24.2px;
      height: 21.86px;
      cursor: pointer;
    }

    .responsive-menu-container {
      z-index: 99999999999999999999999;
      position: absolute;
      background: #fff;
      width: 265px;
      height: 300px;
      top: 9rem;
      left: 0.3rem;
      border-radius: 25px;
      -webkit-border-radius: 25px;
      -moz-border-radius: 25px;
      -ms-border-radius: 25px;
      -o-border-radius: 25px;
      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -ms-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      box-shadow: 20px 20px 40px rgba(0, 0, 0, 0.16);

      @media screen and (max-height: 568px) and (orientation: landscape) {
        z-index: 9999999999;
        position: absolute;
        top: 1.7vh;
        background: #fff;
        width: fit-content;
        height: 60px;
        top: 9rem;
        left: 0.3rem;
        -webkit-border-radius: 15px;
      }

      .header-menu-list {
        // padding-top: 1.7vh !important;
       

        @media screen and (max-height: 568px) and (orientation: landscape) {
          padding: 0 !important;
          display: flex;
        }

        transition: all 0.3s ease-out;
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -ms-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;

        .active-link {
          background: #ebf3ff;

          a {
            color: var(--primary-color) !important;
          }
        }

        li {
          padding: 1.7vh;
          border-radius: 12px;
          -webkit-border-radius: 12px;
          -moz-border-radius: 12px;
          -ms-border-radius: 12px;
          -o-border-radius: 12px;

          a {
            font-size: 18px;
            font-weight: 600;
            text-decoration: none;
            color: var(--black-color);
          }
        }
      }

      @media screen and (max-width: 575px) {
        height: auto;
      }
    }
  }

  @media screen and (max-width: 576px) {
    width: 100vw;
    padding-top: 3vw;
    padding-bottom: 3.2558vw;
    // height: 15vh;
    margin-top: 0;
    top: 0;

    .header-btns-container {
      button {
        @media screen and (max-width: 575px) {
          font-size: 12px !important;
        }
      }
    }
  }

  &[dir="rtl"] {
    .header {
      width: 85.4vw;
      height: 7.125vw;
    }

    .header-search-container {
      position: relative;
    }

    .header-btns-container {
      margin-left: 0;
      margin-right: auto;

      button {
        font-size: 0.937vw;

        @media screen and (max-width: 575px) {
          font-size: 12px;
        }
      }
    }

    .header-second-row {
      .header-menu-container {
        .header-responsive-menu {
          right: 0;
          left: auto;

          ul {
            a {
              padding: 8px 32px 8px 8px;
              text-decoration: none;
              font-size: 25px;
              display: block;
              transition: 0.3s;

              &::after {
                display: none;
              }
            }
          }
        }

        .header-responsive-menu-container-close-btn {
          position: absolute;
          z-index: 2;
          top: 0;
          right: 1.7vh;
          left: auto;
        }

        .search-container {
          left: 0;
          right: 0rem !important;
        }

        .header-menu-list {
          .active-link {
            a {
              &::after {
                content: "";
                position: absolute;
                width: 3.4vh;
                height: 5px;
                background: var(--primary-color) !important;
                right: 0;
                top: 3.4vh;
                border-radius: 1.7vh;
                -webkit-border-radius: 1.7vh;
                -moz-border-radius: 1.7vh;
                -ms-border-radius: 1.7vh;
                -o-border-radius: 1.7vh;
              }
            }
          }
        }
      }
    }
  }
}

.header.ar {
  @media screen and (max-width: 900px) {
    .header-search-container form img {
      position: absolute;
      z-index: 1;
      width: 18.88px;
      height: 18.88px;
    }

    .button.button-secondary {
      font-size: 0.8rem;
    }
  }

  .responsive-menu-container {
    right: 0.3rem;
    left: unset !important;
  }
}