.video-wrapper {
    display: none;
}

.video-wrapper.popup-video {
    display: block;
    width: 100vw;
    height: 300vh;
    background-color: rgba($color: #000000, $alpha: .3);
    position: fixed;
    top: 0;
    backdrop-filter: blur(3px);
    left: 0;
    z-index: 99999;
}


.video-tutorials-card {
    border-radius: 2.5vh;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    background-color: var(--secondary-color);
    overflow: hidden;

    .tutorial-card-gradient {
        width: 100%;
        height: 100%;
        position: absolute;
        display: none;
        object-fit: cover;
    }

    .video-title {
        display: none;
    }


}

.video-tutorials-card:hover {

    .tutorial-card-gradient {

        display: block;
    }

    .video-title {
        display: block;
        position: absolute;
        width: 90%;
        left: 5%;
        bottom: 5%;
        height: fit-content;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        text-align: center;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: white;
        font-size: medium;
        font-weight: 700;
    }



}

.video-tutorials-card.popup-video {
    position: fixed;
    width: 60vw;
    height: 68vh;
    top: 50%;
    left: 50%;
    transform: translate(-55%, -40%);
    background-color: var(--white-color);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px rgba(196, 200, 208, 0.5);
    z-index: 999999999;

    .Xbtn {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 40px;
        cursor: pointer;
                transition: 0.3s;
    }

    .Xbtn:hover {

        transform: scale(1.3);

        cursor: pointer;
    }

    .video-title {
        display: none;
    }

    @media (max-width: 768px) {
        width: 88vw;
        height: 34vh;
        transform: translate(-50%, -40%);
        padding-top: 3rem;


    }
}