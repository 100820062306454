@import "../../index.scss";

.learn-more-article-content-card-img {
  border-radius: 30px 30px 0 0;
  -webkit-border-radius: 30px 30px 0 0;
  -moz-border-radius: 30px 30px 0 0;
  -ms-border-radius: 30px 30px 0 0;
  -o-border-radius: 30px 30px 0 0;

  img {
    width: 100%;

    border-radius: 30px 30px 0 0;
    -webkit-border-radius: 30px 30px 0 0;
    -moz-border-radius: 30px 30px 0 0;
    -ms-border-radius: 30px 30px 0 0;
    -o-border-radius: 30px 30px 0 0;
  }
}

.learn-more-article-content-card {
  display: flex;
  position: relative;
  flex-direction: column;
  box-shadow: 0 0px 20px rgba(196, 200, 208, 0.5);
  width: 100%;
  height: 70.74vh;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  overflow-y: auto;
  scrollbar-width: none;

  .learn-more-article-content-wrapper {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    scrollbar-width: none;
    background: var(--white-color);

    &::-webkit-scrollbar {
      width: 5px !important;
      height: 10vh !important;
      border-radius: 25px !important;
      -webkit-border-radius: 25px !important;
      -moz-border-radius: 25px !important;
      -ms-border-radius: 25px !important;
      -o-border-radius: 25px !important;
      position: relative;

      &::after {
        content: " " !important;
        position: absolute !important;
        top: 50% !important;
        right: 0 !important;
        transform: translate(-50%, -50%) !important;
        height: 80% !important;
        width: 10px !important;
        background: transparent !important;
        pointer-events: none !important;
        -webkit-transform: translate(-50%, -50%) !important;
        -moz-transform: translate(-50%, -50%) !important;
        -ms-transform: translate(-50%, -50%) !important;
        -o-transform: translate(-50%, -50%) !important;
      }
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent !important;
      box-shadow: inset 0 0 5px #dddddd !important;
      border-radius: 4px !important;
      border-left: 1.5px solid transparent !important;
      border-right: 1.5px solid transparent !important;
      border-radius: 25px !important;
      -webkit-border-radius: 25px !important;
      -moz-border-radius: 25px !important;
      -ms-border-radius: 25px !important;
      -o-border-radius: 25px !important;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #d0dbe5 !important;
      border-radius: 4px !important;
      width: 5px !important;
      border-radius: 25px !important;
      -webkit-border-radius: 25px !important;
      -moz-border-radius: 25px !important;
      -ms-border-radius: 25px !important;
      -o-border-radius: 25px !important;
    }
  }

  .learn-more-article-content-card-title {
    background: #fff;
    padding: 1.7vh;
    padding-bottom: 0;

    h2 {
      font-size: 1.375vw;
    }
  }

  .learn-more-article-content-desc {
    a {
      display: block;
    }

    h4 {
      color: #343434;
      text-align: center;
      margin-bottom: 1.7vh !important;
      font-weight: 900;
    }

    h5 {
      margin-bottom: 0.5rem;
      color: #343434;
    }

    p {
      width: 97%;
      // word-wrap: break-word;
      color: var(--paragraph-color);
    }

    display: flex;
    flex-direction: column;
    background: var(--white-color);
    border-end-end-radius: 30px;
    border-end-start-radius: 30px;
    padding-top: 0;
    padding: 0 !important;
    padding-bottom: 3rem !important;
    color: var(--paragraph-color);
    overflow-y: auto;
    overflow-x: clip;
    scrollbar-width: thin !important;
    gap: 1vh;
  }

  @media screen and (max-width: 750px) {
    min-width: 95vw;

    .learn-more-article-content-card-title {
      padding-top: 0 !important;

      h5 {
        font-weight: 600;
        font-size: 2vh !important;
      }
    }

    .learn-more-article-content-desc {
      font-size: 0.8rem !important;
      line-height: 6vw;

      padding-top: 0rem !important;
      padding-bottom: 4rem;
    }
  }

  @media screen and (min-width: 578px) and (max-width: 750px) {
    .learn-more-article-content-desc {
      font-size: 2.3vw !important;
      line-height: 4vw;
      padding-top: 0rem !important;
      padding-bottom: 4rem;
    }
  }
}

// @media screen and (max-width: 575px) {
//   .learn-more-article-content-card {
//     height: 100vw;
//   }
// }
.scrolling-content {
  display: flex;
  overflow: auto;
  flex-direction: column;
  padding: 1.7vh;
  background: var(--white-color);
  border-end-end-radius: 25px;
  border-end-start-radius: 25px;

  @media screen and (max-width: 1275px) {
    padding-inline: 1.7rem;
    padding-inline-end: 0.8rem;
  }
}

.gradient-effect {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 5vh;
  border-end-end-radius: 25px;
  border-end-start-radius: 25px;
}

.gradient-effect.hihi {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 100px;
  border-end-end-radius: 25px;
  border-end-start-radius: 25px;
}
