.faq-container {
  position: relative;

}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: none !important;
}
