@import "../../index.scss";

.brief-section {
  padding-top: 19vh;
  z-index: 1;

  .brief-about-section {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
        justify-content: space-between;
    

    @media screen and (max-width: 1024px) {
      min-height: 0;
      height: 100%;
      justify-content: center;
    }

    @media (max-width: 500px) {
      justify-content: end;
      height: auto;
    }

    .brief-about-section-img {
      margin-top: 5vh;
      width: 20vw;
      height: auto;
      min-width: 250px;
      justify-self: flex-end;

      @media (min-width: 2000px) {
        transform: scale(1.1) !important;
        -webkit-transform: scale(1.1) !important;
        -moz-transform: scale(1.1) !important;
        -ms-transform: scale(1.1) !important;
        -o-transform: scale(1.1) !important;
      }

      @media (min-width: 1600px) {
        margin-top: 6vh;
      }

      @media (min-width: 1000px) {
        margin-inline-end: 6vw;
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
      }

      @media (max-width: 600px) {
        margin-inline-end: 0 !important;
        min-width: auto !important;
        min-height: auto !important;
        width: 40vw;
        position: relative;
        margin-top: 2vh;
      }

      @media (max-width: 575px) {
        width: 60vw;
      }
    }

    .brief-about-section-info {
      display: flex;
      position: relative;
      justify-content: space-between;
      width: 87vw;
      min-width: unset;
      max-height: 60vw;
      

      .brief-about-section-info-title {
        margin-inline-end: 1vw;

        h1 {
          margin-bottom: 0;
        }

        .brief-about-section-info-title-label {
          color: var(--primary-color);
          font-size: 2.8vw;
          margin-top: 2vw;
          font-weight: 600;

          @media (max-width: 920px) {
            font-size: 1.4rem;
            margin-top: 0vw !important;
          }
        }

        @media (max-width: 820px) {
          margin: auto;
          display: flex;
          flex-direction: column;
          justify-content: start;
        }

        // @media (max-width: 920px) and (min-width: 670px) {
        //   //  transform: translateY(9vw);
        // }

        // @media (max-width: 680px) and (min-width: 600px) {
        //   //  transform: translateY(5vw);
        // }

        @media (max-width: 600px) {
          margin: 0 !important;
          // max-width: 40vw;
        }

        @media (min-width: 1400px) {
          margin-inline-end: 5vw;
        }

        @media (min-width: 820px) {
          width: 50%;
          margin-inline-start: 5vw;
        }
      }

      .brief-about-section-info-desc {
        line-height: 1.5;
        font-size: 1.1rem;
        color: #343434;
        margin-bottom: 0;

        @media (max-width: 920px) {
          font-size: 2vw;
        }

        @media (max-width: 670px) {
          font-size: 2.2vw;
        }

        @media (min-width: 500px) and (max-width: 669px) {
          font-size: 2.5vw;
        }

        @media (min-width: 400px) and (max-width: 499px) {
          font-size: 3vw;
        }
      }

      @media (min-width: 2000px) {
        margin-top: 3vh;
      }

      @media (max-width: 920px) {
        .brief-about-section-img {
          align-self: end;
          min-width: 40vw;
          height: auto;
        }

        @media (min-width: 820px) {
          .brief-about-section-img {
            margin-inline-end: 6vw;
          }
        }
      }

      @media (max-width: 768px) {
        margin-top: 6vh;
      }

      @media (max-width: 575px) {
        margin-top: 2vh;
      }

      @media (max-width: 575px) {
        flex-direction: column-reverse;
        max-height: none !important;
      }

      @media (max-width: 820px) {
        width: 90vw !important;
      }
    }

    .services-section {
      max-height: 36vh;
      z-index: 9;

      @media (min-width: 1500px) {
        // transform: translateY(-7vh);
        // -webkit-transform: translateY(-7vh);
        // -moz-transform: translateY(-7vh);
        // -ms-transform: translateY(-7vh);
        // -o-transform: translateY(-7vh);
      }

      .title-with-pagination {
        justify-content: center;
        width: 100%;
        min-width: unset;
        display: flex;

        @media (max-width: 820px) {
          min-width: unset !important;
          width: 90vw;
        }

        .centered-title {
          color: var(--primary-color);
          text-align: center;
          font-size: 3vw;
          font-weight: 600;

          @media (max-width: 920px) {
            font-size: 1.4rem;
            text-align: start;
          }
        }

        .pagination-arrows {
          display: flex;
          gap: 2vw;
          margin-inline-end: 0vw;

          .pagination-arrow-left {
            cursor: pointer;
            width: 25px;
            height: 25px;
            border-radius: 100%;
            background-color: var(--secondary-color);
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .pagination-arrow-left.disabled {
            background-color: var(--tertiary-color);
            pointer-events: none;
          }

          .pagination-arrow-right {
            cursor: pointer;
            width: 25px;
            height: 25px;
            border-radius: 100%;
            background-color: var(--secondary-color);
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .pagination-arrow-right.disabled {
            pointer-events: none;
            background-color: var(--tertiary-color);
          }
        }
      }

      .title-with-pagination.small-screen {
        justify-content: space-between;
        align-items: center;
        margin: auto;
            padding-inline-start: 9vw;
      }

      .our-services-cards-container {
        display: flex;
        align-items: center;
        overflow: auto;
        
        scroll-behavior: smooth;
        gap: 2.5vh;
        padding: 1.5vh;
        width: 87vw;

        @media (max-width: 820px) {
          min-width: unset !important;
          width: 96vw !important;
        }

        @media (min-width: 2000px) {
          gap: 2.5vh;
        }

        & :first-child {
          .service-card-img-container img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .our-services-cards-container.small-screen {
        justify-content: start !important;
      
        width: 87vw;
        // min-width: 760px;

        @media (min-width: 820px) {
          margin-inline-start: 8vw !important;
        }
      }

      .our-services-cards-container::-webkit-scrollbar {
        -webkit-appearance: none;
      }

      .home-services-cards {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.7vh;
      }

      .brief-about-section-services-wrapper::-webkit-scrollbar {
        -webkit-appearance: none;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    min-height: 0;
    height: 100%;
  }
}

.brief-section.ar {
  .brief-about-section-img {
    transform: scaleX(-1);
  }
}

@media screen and (min-width: 2560px) {
  .brief-section {
    padding-top: calc(110px + 6vw);
  }
}