.about-us {
  > div {
    &:nth-child(1) {
      height: auto;
    }
  }
  @media screen and (max-width: 575px) {
    .show-ad-wrapper {
      height: 100vh !important;
      @media screen and (max-height: 568px) and (orientation: landscape) {
        min-height: 100vh;
        height: unset !important;
       }
    }
  }
}
