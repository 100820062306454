@import "../../index.scss";
.layout-footer {

  height: 100vh;

  padding-top: calc(144px + 3vh);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // @media (max-width:768px){
  //   background-image: url("../../../public/assets/MobileBackground.svg");
  // }

  @media screen and (max-height: 568px) and (orientation: landscape) {
    padding-top: 0 !important;
    min-height: 100vh;
    height: unset !important;
  }
  @media screen and (max-width: 576px) {
    padding-top: 10vh;
    height: auto;
    // margin-top: 100px;
  }
  .footer {
    position: relative;
    bottom: 0;
  }
}
.contact-info-app-cards-container-mobile {
  display: flex;
  width: 100%;
  gap: 10px;
}
