@import "../../index.scss";

.contact-us {
  // height: 100vh;
  display: flex;
  align-items: center;
  height: 100%;

  @media screen and (max-height: 568px) and (orientation: landscape) {
    min-height: 100vh;
    height: unset !important;
  }

  .container {
    height: 100% !important;

    @media screen and (max-width: 576px) {
      height: 100vh !important;
    }
  }

  @media screen and (max-width: $Mobile-Breakpoint) {
    padding-top: 0vw;
  }
}

.rc-anchor.rc-anchor-normal.rc-anchor-light {
  border-radius: 1.7vh !important;
  overflow: hidden;
}