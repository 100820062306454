@import "../../index.scss";

.title {
  line-height: 1.7vh;
  color: #3b86ff !important;
  font-weight: 600;
  white-space: nowrap;
  margin-bottom: 2vh;
  font-size: 2.8rem;

  @media screen and (min-width: 2000px) {
    font-size: 3.2rem;
  }

  @media screen and (max-width: 1366px) {
    font-size: 3.4vh;
    margin-bottom: 1.7vh;
  }

  @media screen and (max-width: 850px) {
    font-size: 2.5vh;
  }

  @media screen and (max-width: 576px) {
    font-size: 22px;
  }
}