@import "../../index.scss";

.faq-questions-section {
  height: 100vh;
  position: relative;
  padding-top: calc(144px + 3vh);
  gap: 1.7vh;
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 0 auto;

  @media screen and (max-height: 568px) and (orientation: landscape) {
    min-height: 100vh;
    height: unset !important;
  }

  .faq-pagination-section {
    img {
      cursor: pointer;
    }

    display: flex;
    gap: 1.7vh;
    justify-content: flex-end;
    // margin-bottom:1.7vh;
    font-weight: 600;

    .pagination-section-btns {
      display: flex;
      gap: 0.833333vw;

      button {
        border: none;
        width: 1.45833vw;
        height: 1.45833vw;
        border-radius: 100%;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        -ms-border-radius: 100%;
        -o-border-radius: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // &:disabled {
        //   background: #778289;
        // }
      }
    }
  }

  .faq-ads-cards-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 1.7vh;
  }

  .faq-accordion {
    .faq-card-wrapper {
      display: flex;
      flex-direction: column;
      gap: 1.7vh;

      .faq-accordion-card {
        box-shadow: 0px 0px 20px rgba(196, 200, 208, 0.5);
        border-radius: 1.30208vw;
        -webkit-border-radius: 1.30208vw;
        -moz-border-radius: 1.30208vw;
        -ms-border-radius: 1.30208vw;
        -o-border-radius: 1.30208vw;
        width: 35.104vw;
        height: fit-content;

        .accordion-body {
          color: var(--paragraph-color);
          padding-left: 3.2vw !important;
          padding-top: 0;
          // font-size: 0.8333vw;
          line-height: 1.3;

          @media screen and (max-width: 575px) {
            font-size: 2.79069767vw;
            padding-left: 9vw !important;
          }
        }

        .faq-accordion-card-header {
          display: flex;
          align-items: center;
          border-radius: 25px;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
          position: relative;

          span {
            font-size: 1.04vw;
            font-weight: 600;
            color: #343434 !important;

            @media screen and (max-width: 810px) {
              font-size: 1.7vh !important;
            }

            @media screen and (max-width: 576px) {
              font-size: 14px;
            }
          }

          .accordion-button {
            display: flex;
            gap: 1.7vh;
            border-radius: 1.302vw;
            -webkit-border-radius: 1.302vw;
            -moz-border-radius: 1.302vw;
            -ms-border-radius: 1.302vw;
            -o-border-radius: 1.302vw;
            width: 35.104vw;
            height: 100%;
            padding: 2vh 1.0937vw;
            box-shadow: none;
            border: 0 !important;

            &:focus {
              outline: none;
              box-shadow: none;
              border: 0 !important;
            }

            #QuestiomMark {
              width: 1.4vw;
              height: auto;

              @media screen and (max-width: 576px) {
                width: 5.4vw;
              }
            }

            @media screen and (max-width: 576px) {
              min-width: 90vw;
              height: auto;
              padding: 14px 9px;
              border-radius: 25px;
              -webkit-border-radius: 25px;
              -moz-border-radius: 25px;
              -ms-border-radius: 25px;
              -o-border-radius: 25px;
              gap: 11px;
            }
          }

          .faq-accordion-card-open {
            position: absolute;
            right: 1.7vh;

            img {
              width: 1.35416vw;
              height: auto;
            }

            @media screen and (max-width: 576px) {
              right: 4vw;

              img {
                width: 1.7vh;
              }
            }
          }
        }

        @media screen and (max-width: 576px) {
          min-width: 90vw;
          border-radius: 25px;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
        }
      }

      @media screen and (max-width: 576px) {
        gap: 10px;
      }
    }
  }

  .accordion-button:not(.collapsed)::after {
    content: "";
    background: none;
  }

  .accordion-button.collapsed::after {
    content: "";
    background: none;
  }

  @media screen and (max-width: 576px) {
    height: auto;
    padding-top: 125px;

    .faq-section-title {
      margin-bottom: 4.81395vw;
    }

    .faq-section-title h1 {
      margin-bottom: 0;
    }

    .faq-pagination-section {
      margin-bottom: 5.116279vw;

      .pagination-section {
        p {
          font-size: 3.72093vw;
        }

        .pagination-section-btns {
          button {
            width: 6.279vw;
            height: 6.279vw;
          }
        }
      }
    }
  }

  &[dir="rtl"] {
    .faq-accordion-card-header span {
      text-align: justify;
    }

    .faq-accordion .faq-card-wrapper .faq-accordion-card .accordion-body {
      padding-right: 3.2vw;

      @media screen and (max-width: 575px) {
        padding-right: 11vw;
        padding-left: 0;
      }
    }

    .faq-accordion .faq-card-wrapper .faq-accordion-card .faq-accordion-card-header .faq-accordion-card-open {
      left: 1.7vh;
      right: auto;
    }

    @media screen and (max-width: 575px) {
      .faq-accordion .faq-card-wrapper .faq-accordion-card .faq-accordion-card-header .faq-accordion-card-open {
        left: 4vw;
        right: auto;
      }
    }

    .pagination-section-btns {
      transform: scale(-1);
    }
  }
}

.faq-questions-section-content-wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 4vh;

  .faq-questions-section-content {
    gap: 1.7vh;
    display: flex;

    .accordion-2,
    .accordion-1 {
      display: flex;
      flex-direction: column;
      gap: 1.7vh;
    }

    @media screen and (max-width: 576px) {
      flex-direction: column;
      gap: 10px;
    }
  }

  @media screen and (min-width: 2000px) {
    padding-top: 10rem;
  }

  @media screen and (max-width: 1280px) {
    padding-top: 0;
  }

  @media screen and (max-width: 1024px) {
    padding-top: 3rem;
  }

  @media screen and (max-width: 575px) {
    width: 100%;
    padding-top: 7rem;
  }

  @media screen and (max-width: 375px) {
    padding-top: 0;
  }
}

.accordion-button:not(.collapsed) {
  background: none !important;
  color: black !important;
  border: none !important;
  outline: none !important;
}