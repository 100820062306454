.ayat-section-title {
  margin: 1.1vh;
  text-align: start;
  white-space: nowrap;
  text-align: start !important;
  font-weight: bold;

  .Ayat-header {
    text-align: start !important;
    max-width: 22vh;
    margin: 0;
    font-size: 18px;
    max-height: 0%;
    letter-spacing: 0.05vh;
    position: relative;
    text-align: start !important;
    text-transform: capitalize;
    font-weight: bold;
  }

  .Ayat-header::after {
    content: "";
    position: absolute;
    height: 0.6vh;
    top: 2.5vh;
    width: 4.3vh;
    background-color: dodgerblue;
    border-radius: 0.6vh;
  }
}

.ayat-section-title-en {
  .Ayat-header::after {
    left: 0;
  }
}

.ayat-section-title-ar {
  .Ayat-header::after {
    right: 0;
  }
}