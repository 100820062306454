@import "../../index.scss";

.service-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1vh;
  background: var(--white-color);
  box-shadow: 0px 0px 20px 0px rgba(196, 200, 208, 0.5);
  padding: 2vh;
  border-radius: 25px;
  // width: 260px;
  width: 100%;

  position: relative;
  margin-block: 1.7vh;
  cursor: pointer;

  p {
    font-size: 1.7vh;
    margin: 0;
    font-weight: 600;
    color: #343434;
  }

  .service-card-img-container {
    background: var(--light-blue-color);
    width: 63px;
    height: 63px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    flex-shrink: 0;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .service-card-title {
    font-size: 2vh;
    font-weight: 600;
    line-height: 1.3;
    flex-grow: 1;
    height: 47px;
    overflow: auto;

    @media (max-width: 1500px) {
      font-size: 1.7vh;
      line-height: 1.3;
    }
  }

  .service-card-img-btn {
    align-self: flex-end !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background: var(--primary-color);
    gap: 12px;
    width: 120px;
    height: 40px;
    flex-shrink: 0;
    border: none;
    border-start-end-radius: 25px;
    border-start-start-radius: 10px;
    border-end-end-radius: 25px;
    border-end-start-radius: 10px;
    color: var(--white-color);
    margin-top: auto;
    padding: 10px;
    font-size: 1.7vh;
    font-weight: 600;

    img {
      width: 1.6rem;
      height: 1.6rem;

      @media screen and (max-width: 1600px) {}

      @media screen and (max-width: 1280px) {}
    }
  }
}

@media screen and (max-width: 1600px) {
  .service-card {
    width: 12vw;
    height: 20.462vh;
    min-width: calc(260px * 0.75);
    min-height: calc(221px * 0.75);
    margin-block: 1.7vh;
    margin-top: 0;
  }

  .service-card-img-btn {
    align-self: flex-start !important;
  }

  .service-card-img-container {
    width: 48px !important;
    height: 48px !important;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1024px) {
  .service-card {
    min-height: 0;
    height: auto;
  }
}

@media screen and (max-width: 576px) {
  .service-card {
    //width: 184px;
    width: 185px;
    min-width: 175px;
    height: 165px;
    padding: 0.5rem;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    margin-block: 1.7vh;

    p {
      font-size: 1.7vh;
    }

    .service-card-img-btn {
      font-weight: 600;
      width: 91px;
      height: 31px;
      font-size: 10px;
      align-self: flex-end !important;

      div {
        width: 20px;
        height: 20px;
      }
    }
  }

  .service-card-img-container {
    margin-bottom: 0;
    width: 62px !important;
    height: 62px !important;

    img {
      object-fit: contain;
    }
  }
}