@import "../../index.scss";
.error-page-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  @media screen and (max-height: 568px) and (orientation: landscape) {
    min-height: 100vh;
    height: unset !important;
   }
  .error-page-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 55.62vw;
    height: 47vh;
    // height: 40.0925vh;
    // min-height: 433px;
    // min-width: 1068px;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    box-shadow: 0px 0px 20px rgba(196, 200, 208, 0.5);
    background: #fff;
    .error-page-wrapper-section1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1.5vw;
      img {
        width: 16.614vw;
        height: 23.148vh;
        min-width: 319px;
        min-height: 250px;
      }
    }
    .error-page-wrapper-section2 {
      position: relative;
      height: 100%;
      img {
        width: 28.03385vw;
        // height: 43.6611vh;
        position: relative;
        top: -2vw;
        // min-width: 538px;
        // min-height: 471.5px;
      }
    }
  }
  @media screen and (max-width: 1600px) {
    .error-page-wrapper {
      min-width: calc(1068px * 0.75);
      min-height: calc(433px * 0.75);
      .error-page-wrapper-section1 {
        img {
          min-width: calc(319px * 0.75);
          min-height: calc(250px * 0.75);
        }
      }
      .error-page-wrapper-section2 {
        img {
          min-width: calc(538px * 0.75);
          min-height: calc(471.5px * 0.75);
        }
      }
    }
  }
  @media screen and (max-width: 1280px) {
    .error-page-wrapper {
      min-width: calc(1068px * 0.625);
      min-height: calc(433px * 0.625);
      .error-page-wrapper-section1 {
        img {
          min-width: calc(319px * 0.625);
          min-height: calc(250px * 0.625);
        }
      }
      .error-page-wrapper-section2 {
        img {
          min-width: calc(538px * 0.625);
          min-height: calc(471.5px * 0.625);
        }
      }
    }
  }
  @media screen and (max-width: 992px) {
    .error-page-wrapper {
      min-width: calc(1068px * 0.516);
      min-height: calc(433px * 0.516);
      .error-page-wrapper-section1 {
        img {
          min-width: calc(319px * 0.516);
          min-height: calc(250px * 0.516);
        }
      }
      .error-page-wrapper-section2 {
        img {
          min-width: calc(538px * 0.516);
          min-height: calc(471.5px * 0.516);
        }
      }
    }
  }
  @media screen and (max-width: 576px) {
    justify-content: start;
    padding-top: calc(127px + 10vw);
    .error-page-wrapper {
      min-width: calc(1068px * 0.3);
      min-height: calc(433px * 0.3);
      width: 90vw;
      height: 188px;
      .error-page-wrapper-section1 {
        img {
          height: 96px;
          width: 130px;
          min-width: calc(319px * 0.3);
          min-height: calc(250px * 0.3);
        }
      }
      .error-page-wrapper-section2 {
        img {
          width: 204px;
          height: 178px;
          min-width: calc(538px * 0.3);
          min-height: calc(471.5px * 0.3);
        }
      }
    }
  }
}
