@import "../../index.scss";

.pagination-section {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 1.7vh;
  margin-bottom: 1.0416vw;

  @media screen and (max-width: 576px) {
    // margin-bottom: 8px;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 0.83333333rem;
    text-transform: capitalize;
    font-weight: bold;

    @media screen and (min-width: 2000px) {
      font-size: 2.5vh;
    }
  }

  .pagination-section-btns {
    display: flex;
    align-items: center;
    gap: 1.7vh;
    height: 30px;
    padding: 2px;
    cursor: pointer;
  }

  img {
    cursor: pointer;
  }
}

[dir="rtl"] {
  .h-pagination-arrow {
    transform: scaleX(-1);
  }
}

.pagination-section-read-mode {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.7rem;

  .pagination-section-read-mode-note {
    display: flex;
    justify-content: space-between;

    p {
      width: 22vw;
      flex-grow: 1;
      margin: 0;
      font-size: 1.375rem;
      font-weight: 600;
      color: var(--secondary-color);
    }
  }

  .pagination-section-read-mode-s2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pagination-section-btns {
    display: flex;
    align-items: center;
    gap: 0.8vw;
    transform: scaleY(-1);
    height: 30px;
    padding: 2px;
  }

  &[dir="rtl"] {
    .pagination-section {
      justify-content: start;
    }
  }

  @media screen and (max-width: 1600px) {
    // width: 24vw !important;
  }

  @media screen and (max-width: 576px) {
    // padding: 0 1.7vh;
    width: 100%;
    margin-bottom: 3.48837vw;

    .pagination-section-read-mode-note {
      display: flex;
      justify-content: space-between;

      p {
        flex-grow: 1;
        width: 22vw;
        font-size: 1.7vh;
      }
    }
  }
}

.App.ar {
  .pagination-section-btns {
    flex-direction: row-reverse !important;
    transform: scale(-1);
  }
}

.h-pagination-arrow {
  cursor: pointer;
  height: 25px;
  width: 25px;
}

.pagination-arrow-container {
  display: flex;
  gap: 0.7rem;
}