.search-card {
  position: relative;
  display: flex;
  gap: 0.5rem;
  height: 10vh;
  min-height: 70px;
  padding-inline-start: 0.5rem;
  align-items: center;
  border-block: 1px solid #f7f7f7;
  border-bottom: 1px solid #ddd;

  .doctor-avatar {
    object-fit: cover;
    border-radius: 100%;
    height: 75%;
    background-color: transparent;
    aspect-ratio: 1/1;
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;

    h4 {
      font-size: 2.5vh;
      margin: 0;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-weight: bold;
    }

    p {
      color: var(--paragraph-color);
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-height: 100px;
      overflow: auto;
      line-height: 1.3;
      font-size: large;
    }
  }

  .article-headline-more-btn-container {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    position: absolute;
    bottom: -0.7rem;
    color: var(--primary-color);
    cursor: pointer;
    font-weight: 600;

    img {
      width: 1.1rem;
    }
  }
}

.search-card.ar {
  .article-headline-more-btn-container {
    left: 0.7rem;
  }
}

.search-card.en {
  .article-headline-more-btn-container {
    right: 0.7rem;
  }
}