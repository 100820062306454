@font-face {
  font-family: "comfortaa";
  src: url("./fonts/comfortaa_regular-webfont.woff2") format("woff2");
}



@font-face {
  font-family: 'GE_SS_Two';
  src: local('GE_SS_Two Regular'),
    url('../public/Fonts/GESSTwoMedium-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: "comfortaa", sans-serif;
}

[dir="rtl"] {
  * {
    font-family: "GE_SS_Two", sans-serif;
  }
}

p::-webkit-scrollbar {
  -webkit-appearance: none;
}

div::-webkit-scrollbar {
  -webkit-appearance: none;
}

textarea::-webkit-scrollbar {
  width: 0px;
}

body::-webkit-scrollbar {
  -webkit-appearance: none;
}

body::-webkit-scrollbar {
  width: 0px;
}

body::-webkit-scrollbar-thumb {
  background-color: transparent;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
span,
img {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

h5 {
  margin: 0 !important;
}

button {
  color: white !important;
}

button * {
  color: white !important;
  scrollbar-width: 0 !important;
}

body,
html {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  height: 100vh;
  min-height: 100vh;
  scroll-snap-type: y mandatory;

  @media screen and (max-height: 568px) and (orientation: landscape) {
    height: unset;
    min-height: 100vh;
  }
}

#root {
  --primary-color: #3b86ff;
  --secondary-color: #43425d;
  --light-secondary-color: #f1f1f3;
  --tertiary-color: #778289;
  --black-color: #000;
  --white-color: #fff;
  --paragraph-color: #778289;
  --light-primary-color: #ebf3ff;
  --light-blue-color: #f5f6fa;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: 1.6;
}

p {
  margin: 0;

  line-height: 1.6;
}

ul,
ol {
  list-style: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.terms-of-use {
  height: 100vh !important;
}

body {
  background-image: url("../public/assets/Appwaves.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  @media (max-width: 768px) {
    background-image: url("../public/assets/MobileBackground.svg");
  }
}

.r-p {
  @media (min-width: 1920px) {
    font-size: 0.9vw !important;
  }

  @media (min-width: 1600px) and (max-width: 1919px) {
    font-size: 1.7vh !important;
  }

  @media (min-width: 1440px) and (max-width: 1599px) {
    font-size: 1.1vw !important;
  }

  @media (min-width: 1200px) and (max-width: 1439px) {
    font-size: 1.2vw !important;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 1.5vw !important;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 1.7vw !important;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 2.3vw !important;
  }

  @media (min-width: 500px) and (max-width: 575px) {
    font-size: 3.2vw !important;
  }

  @media (max-width: 499px) {
    font-size: 3.8vw !important;
  }
}

.r-s-p {
  @media (min-width: 1920px) {
    font-size: 0.95vw !important;
  }

  @media (min-width: 1600px) and (max-width: 1919px) {
    font-size: 1.7vh !important;
  }

  @media (min-width: 1440px) and (max-width: 1599px) {
    font-size: 1.1vw !important;
  }

  @media (min-width: 1200px) and (max-width: 1439px) {
    font-size: 1.2vw !important;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 1.4vw !important;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 1.6vw !important;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 1.8vw !important;
  }

  @media (min-width: 500px) and (max-width: 575px) {
    font-size: 2.8vw !important;
  }

  @media (max-width: 499px) {
    font-size: 3vw !important;
  }
}

.blurEffectCard {
  position: fixed;
  top: -1.7vh;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1110;
  background-color: rgba(255, 255, 255, 0.15);
  filter: brightness(90%);
  backdrop-filter: blur(2px);
  // opacity: 0.4;
}

$Desktop-breakpoint: 1920px;
//   xxl: 1600px
$Large-Labtop-Breakpoint: 1600px;
//   xl: 1200px,
$Medium-Labtop-Breakpoint: 1280px;
//   lg: 992px,
$Small-Labtop-Breakpoint: 992px;

$Tablets-Breakpoint: 768px;
//   sm: 576px,
$Mobile-Breakpoint: 576px;
//   xs: 450px,
//   md: 768px,
// 768px: 768px;
//   sm: 576px,
// 576px: 576px;
//   xs: 450px,
$Extra-Small-Mobile-Breakpoint: 430px;
// ? ==============================================================Width Ratios================================================
$Laptop-Size-Ratio: 0.75;
$Medium-Size-Ratio: 0.625;
$Small-Size-Ratio: 0.516;
$Tablets-Size-Ratio: 0.4;
$Mobile-Size-Ratio: 0.3;
$Laptop-height-Size-Ratio: 0.7111;
$Medium-height-Size-Ratio: 0.6667;
$Small-height-Size-Ratio: 0.6889;

html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  font-size: 3vh;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

pre {
  // font-family: monospace, monospace; /* 1 */
  font-size: 1.7vh;
  /* 2 */
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-size: 1.7vh;
  /* 2 */
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

button,
input {
  /* 1 */
  overflow: visible;
}

button,
select {
  /* 1 */
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

.fade {
  transition: none !important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}