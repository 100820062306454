.search-card-container {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  padding-inline: 1.7vh;
  width: 51.041666vw;
  justify-content: space-between;
  position: relative;
  align-content: start;
  height: 100%;
  background: var(--white-color);
  -webkit-border-radius: 1.7vh;
  -moz-border-radius: 1.7vh;
  -ms-border-radius: 1.7vh;
  -o-border-radius: 1.7vh;
  border-radius: 1.7vh;
  position: relative;
  box-shadow: 0px 0px 20px 0px rgba(196, 200, 208, 0.5);
  overflow: auto;

  .search-card {
    padding: 0.5rem;
    width: 40%;
    border-top: none;
    height: 33% !important;
  }

  @media (max-width: 767px) {
    width: 90vw;
    margin-top: 1.7vh;
    height: 57vh;

    .search-card {
      padding: 0.5rem;
      width: 100%;
      border-top: none;
      height: 25% !important;
    }
  }

  .about-us-section-title {
    margin-bottom: 1.7vh !important;

    h1 {
      margin-bottom: 1.7vh !important;
    }
  }
}

@media (max-width: 1024px) {
  .search-card-container {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 2.5vh;
  }
}

@media (min-width: 1024px) {
  .search-card-container {
    width: 100%;
    min-width: 50vw;
    height: 90%;
    justify-content: space-between;
    padding: 2.5vh;
  }
}

@media (min-width: 767px) {
  .search-card-container::after {
    content: "";
    width: 2px;
    background-color: lightgray;
    height: 100%;
    left: 50%;
    position: absolute;
  }
}