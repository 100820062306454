@import "../../index.scss";

#our-app-ads-cards-wrapper-col {
  @media screen and (max-width: 1280px) {
    display: none;
  }
}

.tabs-background-mobile {
  content: " ";
  background: #f5f6fa;
  position: absolute;
  height: 100%;
  border-radius: 25px;
  width: 21vw;
  z-index: 0;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  left: 3.4vw;
  top: 8px;
}

.firstTab {
  margin-top: 0;
}

.secondTab {
  margin-top: 7px;
}

.our-app-ads-cards-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 3rem;

  @media screen and (min-width: 1445px) {
    margin-top: 5rem;
  }
}

.nav-tabs {
  width: fit-content !important;
  min-width: 500px;
  justify-content: center;
  margin: 0 auto !important;
  border: none !important;
  padding: 5px !important;
  background: var(--light-blue-color) !important;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  position: absolute;
  left: 50%;
  top: 25%;
  transform: translate(-50%, -50%);

  @media screen and (max-height: 580px) and (orientation: landscape) {
    top: 40.5vh;
  }

  @media screen and (max-width: 1366px) {
    top: 25%;
  }

  @media screen and (max-width: 1280px) {
    top: 25%;
  }

  @media screen and (max-width: 992px) {
    top: 20%;
  }

  @media screen and (max-width: 676px) {
    width: 319px !important;
    min-width: 270px !important;
    position: relative;
    top: 1.9vw;
    left: 0;
    transform: none;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    margin-bottom: 2.9069vw !important;

    li {
      width: 50% !important;

      button {
        width: 100% !important;
      }
    }
  }

  @media screen and (max-width: 375px) {
    width: 275px !important;
  }

  li button {
    border: none !important;
    text-transform: capitalize;
  }

  .nav-item {
    flex-grow: 1;
    width: 33.3;

    button {
      font-size: 2vh;

      color: var(--secondary-color);
      height: 100%;
      width: 100%;

      @media screen and (max-width: 1600px) {
        font-size: 1.7vh;
      }

      @media screen and (max-width: 1280px) {
        font-size: 13px;
      }

      @media screen and (max-width: 676px) {
        font-size: 17px;
      }

      @media screen and (max-width: 375px) {
        font-size: 14px;
      }
    }

    &:first-of-type {
      width: 33.33%;

      .active {
        background: var(--secondary-color) !important;
        border-start-start-radius: 25px;
        border-start-end-radius: 0;
        border-end-start-radius: 25px;
        border-end-end-radius: 0;
        font-size: 2vh;
        font-weight: 600;

        color: var(--white-color) !important;

        @media screen and (max-width: 1600px) {
          font-size: 1.7vh;
        }

        @media screen and (max-width: 1280px) {
          font-size: 13px;
        }

        @media screen and (max-width: 676px) {
          font-size: 17px;
        }
      }
    }

    &:nth-of-type(2) {
      width: 33.33%;
      border: 0 !important;

      .active {
        background: var(--secondary-color) !important;
        border-radius: 0;
        font-size: 2vh;
        font-weight: 600;

        color: var(--white-color) !important;

        @media screen and (max-width: 1600px) {
          font-size: 1.7vh;
        }

        @media screen and (max-width: 1280px) {
          font-size: 13px;
        }

        @media screen and (max-width: 676px) {
          font-size: 15px;
          border-start-start-radius: 0;
          border-start-end-radius: 25px;
          border-end-start-radius: 0;
          border-end-end-radius: 25px;
        }
      }
    }

    &:nth-of-type(3) {
      width: 33.33%;

      .active {
        background: var(--secondary-color) !important;
        border-start-start-radius: 0;
        border-start-end-radius: 25px;
        border-end-start-radius: 0;
        border-end-end-radius: 25px;
        font-size: 2vh;
        font-weight: 600;

        color: var(--white-color) !important;

        @media screen and (max-width: 1600px) {
          font-size: 1.7vh;
        }

        @media screen and (max-width: 1280px) {
          font-size: 13px;
        }

        @media screen and (max-width: 676px) {
          border-start-start-radius: 0;
          border-start-end-radius: 0;
          border-end-start-radius: 25px;
          border-end-end-radius: 25px;
          font-size: 15px;
        }
      }
    }
  }

  &[dir="rtl"] {
    .tabs-background-mobile {
      left: auto;
      right: 5px;
    }
  }
}

.services-tab-panel {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.25vw;
  padding-top: 1.5vw;

  .our-services-tab-cards-container {
    background: var(--light-blue-color);
    height: 48.5vh;
    width: 67.85vw;
    min-width: 600px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 1.75vw;
    padding: 2vh;
    position: relative;
    padding-inline: 3.4vh;

    @media screen and (min-width: 2000px) {
      padding-inline: 3.5rem;
    }

    @media screen and (max-width: 1000px) {
      width: 85vw;
    }

    @media screen and (max-width: 1280px) {
      padding-top: 1.5vw;
    }

    @media screen and (max-width: 992px) {
      padding-top: 1.5vw;
    }

    @media screen and (max-width: 768px) {
      gap: 1.5vh;
      margin-inline-start: -6vw;
      display: flex;
      flex-direction: column;
      background: none;
      min-width: 0 !important;
      width: 95vw;
      padding-top: 7vw;
      height: 64vh;
      align-items: start;
      margin-inline-start: 0;

      .our-app-service-card-active,
      .our-app-account-card-active {
        @media screen and (max-width: 375px) {
          min-width: 85vw;
        }
      }

      .our-app-service-card-active-mobile-wrapper {
        gap: 6vw;
      }

      .our-app-service-card-desc {
        overflow: auto;
        line-height: 1.3;
        font-weight: 600;
      }

      .our-app-service-card-icon-container {
        flex-shrink: 0;
        width: 11.54vw;
        height: 11.54vw;

        min-height: 40px;
        min-width: 40px;
      }
    }

    @media screen and (max-width: 575px) {
      top: 0;
      justify-content: space-around;
    }
  }

  &[dir="rtl"] {
    .tabs-background-mobile {
      right: 2vw;
    }
  }

  .our-services-tab-cards-title {
    z-index: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    width: fit-content;
    top: 3rem;
    left: 0vw;

    .our-app-title {
      color: var(--primary-color);
      font-weight: 600;
      text-transform: capitalize;

      @media screen and (max-width: 375px) {
        font-size: 1.7vh;
        margin-bottom: 0;
      }
    }

    @media screen and (min-width: 2000px) {
      top: 0;
    }

    @media screen and (max-width: 1600px) {
      top: 3rem;
    }

    @media screen and (max-width: 1280px) {
      top: 5vh;
    }

    @media screen and (max-width: 850px) {
      top: 0rem;
    }

    @media screen and (max-width: 768px) {
      .title {
        font-size: 32px;
      }
    }

    @media screen and (max-width: 676px) {
      top: 0;
      left: 4vw;
      margin-bottom: 3.5813953vw;

      h4 {
        font-size: 14px;
      }

      .title {
        font-size: 1.7vh;
        margin-bottom: 0.5rem;
      }
    }

    .select-tabs-points {
      height: 30px;
      display: flex;
      gap: 2.5vh;
      transform: translateY(1vh);

      @media screen and (max-width: 676px) {
        height: auto;
      }

      .toggleCircle {
        content: " ";
        display: flex;
        position: relative;
        background: transparent;
        width: 15px;
        height: 15px;
        flex-shrink: 0;
        border-radius: 100%;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        -ms-border-radius: 100%;
        -o-border-radius: 100%;
        cursor: pointer;
        border: 2px solid var(--secondary-color);
        transform: scale(0.7);
        -webkit-transform: scale(0.7);
        -moz-transform: scale(0.7);
        -ms-transform: scale(0.7);
        -o-transform: scale(0.7);
      }

      .activeCircle {
        content: " ";
        display: flex;
        position: relative;
        background: var(--secondary-color);
        width: 15px;
        height: 15px;
        flex-shrink: 0;
        border-radius: 100%;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        -ms-border-radius: 100%;
        -o-border-radius: 100%;
        cursor: pointer;
        border: 2px solid var(--secondary-color);
      }
    }
  }

  .d-flex.gap-3 {
    height: 48.5vh;
    width: 85vw;
    display: flex;
    gap: 0.75rem;

    .ads-container {
      height: 100% !important;
    }

    // @media (max-width: 900px) {
    //   position: relative;
    //   top: 3rem;
    // }
    @media (max-width: 900px) {
      height: unset;
      width: unset;
      justify-content: center;
    }
  }

  .our-app-service-card-footer-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: end;

    .our-app-service-card-footer-icon-wrapper {
      background: var(--primary-color);
      width: 46px;
      height: 46px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      align-self: flex-end;
      border-radius: 100%;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      -ms-border-radius: 100%;
      -o-border-radius: 100%;
      margin-top: auto;
      position: absolute;
      bottom: 20px;

      img {
        width: 100%;
        height: 100%;
      }

      @media screen and (max-width: 1600px) {
        width: 4vh;
        height: 4vh;
      }

      @media screen and (max-width: 1280px) {
        width: 25px;
        height: 25px;
      }

      @media screen and (max-width: 992px) {
        width: 25px;
        height: 25px;
      }
    }
  }

  button {
    color: #43425d !important;
  }

  &[dir="rtl"] {
    .our-services-tab-cards-title {
      right: 1.7vh;
      left: 0;

      @media screen and (max-width: 676px) {
        right: 0rem;
      }
    }
  }

  @media screen and (min-width: 1921px) {
    padding-top: 4.5vw !important;
  }

  @media screen and (max-width: 1440px) {
    padding-top: 0 !important;
  }

  @media screen and (max-width: 992px) {
    height: 53vh;
  }

  @media screen and (max-width: 676px) {
    height: 72vh;
    gap: 0;
  }

  @media screen and (max-width: 575px) {
    height: 65vh;
  }

  @media screen and (max-width: 576px) {
    width: 100vw;
  }

  @media screen and (max-width: 375px) {
    height: 60vh;
  }

  .our-services-tab-cards-container.grid-tutorials {
    display: grid;
    grid-template-columns: repeat(3, 28%);
    grid-template-rows: repeat(2, 40%);
    height: 60vh;
    gap: 1.7vh;
    position: relative;
    align-content: center;

    .pagination-dots {
      display: flex;
      gap: 5px;
      bottom: 20px;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);

      .dot {
        width: 15px;
        height: 15px;
        background-color: var(--primary-color);
        cursor: pointer;
        border-radius: 10px;
        opacity: 0.3;
      }

      .dot.active {
        width: 60px;
        transition: 0.3s;
        opacity: 1;
      }
    }

    @media (max-width: 900px) {
      display: flex;
      flex-direction: column;
      height: 63vh;
      background-color: #f5f6fa;
      padding-top: 1.7vh;
      padding-bottom: 3rem;
      top: 3.4vh;
      margin: 0;
    }

    @media (max-width: 575px) {
      top: 1.7vh;
    }
  }

  .d-flex.gap-3 {
    display: flex;
    gap: 0.75rem;
  }

  .our-services-tab-cards-container.grid-tutorials+.ads-container {
    height: 123% !important;
  }
}

.our-app-servies-section.en .our-app-service-card-footer-icon-wrapper,
.our-app-servies-section.en .our-app-account-card-footer {
  @media (max-width: 668px) {
    right: 10px;
    bottom: 10px;
    width: 26px;
    height: 26px;
  }
}

.our-app-servies-section.ar .our-app-service-card-footer-icon-wrapper,
.our-app-servies-section.ar .our-app-account-card-footer {
  @media (max-width: 668px) {
    left: 10px;
    bottom: 10px;
    width: 26px;
    height: 26px;
  }
}