@import "../../index.scss";

.search-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: var(--light-secondary-color);
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
  padding: 0.6rem 1.7vh;
  gap: 24px;
  position: relative;
  left: 3rem;
  width: 20.77vw;
  height: 2.6041666vw;
  min-width: 322px;
  min-height: 30px;

  form {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.3541vw;
    width: 99%;

    #site-search {
      width: 99%;
      text-align: initial !important;

      @media screen and (max-width: 1366px) {
        &::placeholder {
          font-size: 0.8rem;
        }
      }

      @media screen and (max-width: 900px) {
        left: 4vw;
      }

      @media screen and (max-width: 575px) {
        width: 52%;
      }

      @media screen and (max-width: 350px) {
        left: 0;
      }
    }

    img {
      width: 20px;
      height: auto;
    }

    input {
      border: none;
      background: var(--light-secondary-color);
      position: relative;
      width: 100%;

      &:focus {
        outline: none !important;
      }

      &::-webkit-input-placeholder {
        font-size: 1.7vh;
        color: var(--tertiary-color);
        font-weight: 600;
      }
    }

    &::before {
      content: "";
      position: absolute;
      width: 2px;
      height: 100%;
      background: #e2e4e8;
      top: 1px;
      display: inline-block;
      left: 30px;
    }
  }

  @media screen and (max-width: 900px) {
    width: 13rem;
  }

  @media screen and (max-width: 575px) {
    position: initial;
  }
}

.search-menu {
  overflow: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 1.7vh;
  position: absolute;
  z-index: 1111111111;
  top: 60px;
  left: 0;
  width: 100%;
  height: auto;
  box-shadow: 0px 0px 20px 0px rgba(196, 200, 208, 0.5);
  gap: 1.7vh;

  .header-container {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    justify-content: space-between;

    a {
      color: var(--primary-color);
    }
  }
}

@media screen and (max-width: $Large-Labtop-Breakpoint) {
  .search-container {
    left: 20px;
    min-width: 126px;
    min-height: 40px;
  }
}

@media screen and (max-width: 1000px) {
  .search-container {
    left: 1.7vh;
  }
}

@media screen and (max-width: $Mobile-Breakpoint) {
  .search-container {
    width: 9rem;
    min-width: 3rem;
    min-height: 40px;
    padding: 1.7vh;
    margin-inline-start: 0.5rem;

    form {
      height: 100%;

      img {
        left: 3px !important;
      }

      &::before {
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;
        background: #e2e4e8;
        top: 0px;
        display: inline-block;
        left: 22%;
        z-index: 11;
      }
    }

    .search-menu {
      width: 56.41vw;
      top: 9rem;
      left: 4vh;
    }
  }
}

@media screen and (max-width: 375px) {
  .search-container {
    width: 6rem;
    margin: 0;

    .search-menu {
      top: 8rem;
      left: 2.5vh;
    }
  }
}

[dir="rtl"] {
  @media screen and (max-width: $Small-Labtop-Breakpoint) {
    .search-container {
      justify-content: flex-end;
    }
  }

  @media screen and (max-width: $Mobile-Breakpoint) {
    .search-container {

      // right: 2.5vh;
      // form {
      //   &::before {
      //     left: auto !important;
      //     right: 30% !important;
      //   }
      // }
      .search-menu {
        left: -24.359vw;
      }
    }
  }
}

.m_align {
  margin-inline-start: 3.4vh;
}