@import "../../index.scss";

.tab-pane {
  height: 100%;
}

.about-us-tab-detail {
  display: flex;
  width: 51.041666vw;
  height: 100%;
  background: var(--white-color);
  border-radius: 1.302vw;
  -webkit-border-radius: 1.302vw;
  -moz-border-radius: 1.302vw;
  -ms-border-radius: 1.302vw;
  -o-border-radius: 1.302vw;
  position: relative;
  box-shadow: 0px 0px 20px 0px rgba(196, 200, 208, 0.5);

  .about-us-tab-detail-info {
    width: 60%;
    padding: 4.85416vw 3.3958vw;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    gap: 0.625vw;

    h2 {
      font-size: 1.3020833vw;
      font-weight: 600;
      color: var(--primary-color);
      margin-bottom: 0;
    }

    p {
      font-size: 0.9375vw;
      font-weight: 600;
      width: 80%;
      line-height: 1.5625vw;
      margin-bottom: 0;
    }
  }

  .about-us-tab-detail-img {
    display: flex;
    flex-direction: column;
    width: 23.958vw;
  }

  &[dir="rtl"] {
    .about-us-tab-detail-img {
      img {
        left: -12rem;
        right: auto;

        @media screen and (max-width: 576px) {
          left: 0;
        }
      }
    }

    .about-us-tab-detail-info {
      padding-left: 0;
      padding-right: 2.3958vw;
    }
  }

  @media screen and (max-width: 1450px) {
    height: 100%;

    .about-us-tab-detail-info {
      justify-content: center;

      p {
        line-height: 2.068965vw;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;

    .about-us-tab-detail-info {
      h2 {
        font-size: 3.4vh;
      }

      p {
        font-size: 2vh !important;
        line-height: normal !important;
      }
    }

    .about-us-tab-detail-img {
      width: 35vw;
    }
  }

  @media screen and (max-width: 900px) {
    height: 40vh;
    width: 100%;
    flex-direction: column;
    position: relative;
    justify-content: end;
    border-radius: 2.9976019vw;
    -webkit-border-radius: 2.9976019vw;
    -moz-border-radius: 2.9976019vw;
    -ms-border-radius: 2.9976019vw;
    -o-border-radius: 2.9976019vw;
    align-items: end;

    .about-us-tab-detail-info {
      width: 100%;
      order: 2;
      padding-top: 0;
      width: 90vw;

      h2 {
        font-size: 2.15827338vw;
      }

      p {
        font-size: 3.25581395348837vw;
        line-height: 1.3;
      }
    }

    .about-us-tab-detail-img {
      display: flex;
      flex-direction: column;
      width: 40vw;
      order: 1;
      display: flex;
      flex-direction: column;
      align-items: end;
      position: relative;
      top: 0vw;

      img {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 576px) {
    flex-direction: column;
    border-radius: 5.8139534vw;
    -webkit-border-radius: 5.8139534vw;
    -moz-border-radius: 5.8139534vw;
    -ms-border-radius: 5.8139534vw;
    -o-border-radius: 5.8139534vw;
    justify-content: end;

    .about-us-tab-detail-info {
      width: 100%;
      order: 2;
      height: 22vh;
      padding-top: 0;

      h2 {
        font-size: 5.18604651vw;
      }

      p {
        font-size: 3.25581395348837vw;
        line-height: 4.9767vw;
      }
    }

    .about-us-tab-detail-img {
      display: flex;
      flex-direction: column;
      width: 60vw;
      order: 1;
      display: flex;
      flex-direction: column;
      align-items: end;
      position: relative;

      img {
        width: 100%;
      }
    }
  }
}

#WhoWeAre {
  position: relative;
  right: 1.7vh;
  top: 1.5vh;

  @media screen and (max-width: 575px) {
    top: -1vh;
    right: -3vw;
    transform: scale(0.85);
  }
}

#Mission {
  right: 2.4vw;
  position: relative;
  top: 7.5vh;
  transform: scale(1.3);

  @media screen and (max-width: 850px) {
    transform: scale(1.2);
    right: 0vw;
    top: -10vw;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
  }

  @media screen and (max-width: 575px) {
    transform: scale(1);
    right: -1vw;
    top: -10vw;
  }
}

#Vision {
  right: 0.5vw;
  top: -0.5vw;
  position: relative;
  transform: scale(1.08);

  @media screen and (max-width: 1440px) {
    transform: scale(1.1);
    right: 0vw;
    top: 0vw;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
  }

  @media screen and (max-width: 575px) {
    right: 0.5rem;
  }
}

#OurValue {
  right: 2.4vw;
  position: relative;
  top: 17vh;
  transform: scale(1.2);

  @media screen and (max-width: 900px) {
    top: -5vw;
  }

  @media screen and (max-width: 575px) {
    transform: scale(1);
    right: 0vw;
    top: -9vw;
  }
}

[dir="rtl"] {
  #WhoWeAre {
    left: 1.7vh;
    right: auto;
    transform: scaleX(1);

    // transform: scaleY(1.1);
    @media screen and (max-width: 575px) {
      transform: scaleX(1);
      -webkit-transform: scaleX(1);
      -moz-transform: scaleX(1);
      -ms-transform: scaleX(1);
      -o-transform: scaleX(1);
    }
  }

  #Mission {
    right: -2vw;

    @media screen and (max-width: 575px) {
      transform: scale(1);
      left: -3vw;
      top: 3vw;
    }
  }

  #Vision {
    right: -2vw;
    transform: scale(1.08, 1.08);

    @media screen and (max-width: 575px) {
      transform: scaleX(1);
      -webkit-transform: scaleX(1);
      -moz-transform: scaleX(1);
      -ms-transform: scaleX(1);
      -o-transform: scaleX(1);
      top: -4vw;
    }
  }

  #OurValue {
    right: -2.4vw;
    transform: scale(1.2, 1.2);

    @media screen and (max-width: 575px) {
      transform: scaleX(-0.9);
      right: 3.3vw;
    }
  }
}