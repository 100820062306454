@import "../../index.scss";

.contact-us-section {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 1.348958vw;
  position: relative;

  .contact-us-form-wrapper {
    display: flex;
    gap: 0.833333vw;
    height: 48vh;
    position: relative;
    top: 2vw;
  }

  @media screen and (max-width: 1600px) {
    width: 100vw;
    margin: 0 auto;
    position: relative;
    top: -2vw;
  }

  @media screen and (max-width: 1024px) {
    top: 0;
    flex-direction: column;
    gap: 3.4vh;
  }

  @media screen and (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 4.65217391vw;
    gap: 3.48837vw;
    padding-bottom: 5.627906976744vw;
  }

  .contact-info-msg {
    p {
      font-weight: 600;
      margin-bottom: 0;
      line-height: 32px;
    }
  }
}

#WaveContact {
  width: 100vw;
  position: absolute;
  z-index: -1;
  top: 12vh;
  display: none;
}