@import "../../index.scss";

.mobile-app-card {
  display: flex;
  align-items: center;
  gap: 0.557291vw;
  box-shadow: 0 3px 47px rgba(0, 0, 0, 0.12);
  padding: 0.83333vw 1.0416vw;
  border-radius: 1.7vh;
  -webkit-border-radius: 1.7vh;
  -moz-border-radius: 1.7vh;
  -ms-border-radius: 1.7vh;
  -o-border-radius: 1.7vh;
  flex-grow: 1;
  background-color: var(--white-color);
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;

  &:hover {
    cursor: pointer;
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);

  }

  p {
    margin: 0;
  }

  img {
    width: 1.66666vw;
    height: auto;

    @media screen and (max-width: 992px) {
      width: 4vh;
    }
  }

  .mobile-app-card-info {
    display: flex;
    flex-direction: column;

    .mobile-app-card-desc {
      color: var(--paragraph-color);
    }

    @media screen and (max-width: 1366px) {

      .mobile-app-card-title,
      .mobile-app-card-desc {
        font-size: 0.8rem !important;
      }
    }
  }

  @media screen and (max-width: 992px) {
    gap: 1.7vh;
    padding: 1.7vh;
  }

  @media screen and (max-width: $Mobile-Breakpoint) {
    background: #fff;
    gap: 10px;
    width: 42.1395vw;
    height: 13.953vw;
    padding: 14px 18px;

    img {
      width: 6.27906vw;
    }

    border-radius: 1.86vw;
    -webkit-border-radius: 1.86vw;
    -moz-border-radius: 1.86vw;
    -ms-border-radius: 1.86vw;
    -o-border-radius: 1.86vw;

    .mobile-app-card-info {
      .mobile-app-card-desc {
        font-size: 9px !important;
      }

      .mobile-app-card-title {
        font-size: 2.790697vw;
      }
    }
  }
}

[dir="rtl"] {
  @media screen and (max-width: $Mobile-Breakpoint) {
    .mobile-app-card {
      background: #fff;
      gap: 10px;

      padding: 14px 18px;

      img {
        width: 6.27906vw;
      }

      border-radius: 1.86vw;
      -webkit-border-radius: 1.86vw;
      -moz-border-radius: 1.86vw;
      -ms-border-radius: 1.86vw;
      -o-border-radius: 1.86vw;

      .mobile-app-card-info {
        .mobile-app-card-desc {
          font-size: 2.093vw;
        }

        .mobile-app-card-title {
          font-size: 2.790697vw;
        }
      }
    }
  }
}