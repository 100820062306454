@import "../../index.scss";

.ads-container {
  max-height: 54vh;
  width: 13vw;
  // height: 100%;
  aspect-ratio: 2.6/4.77;
  background: var(--secondary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #d0dbe5;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
  overflow: hidden;

  .ad-img {
    width: 100%;
    height: 100%;
  }

  h1 {
    font-size: 4.27vw;
    color: #fff !important;
  }

  @media screen and (max-width: $Large-Labtop-Breakpoint) {}

  @media screen and (max-width: $Medium-Labtop-Breakpoint) {}

  @media screen and (max-width: $Small-Labtop-Breakpoint) {
    width: auto;
  }

  @media screen and (max-width: $Mobile-Breakpoint) {
    position: absolute;
    right: 2vw;
    z-index: 11111111111;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background: var(--primary-color);
    box-shadow: 0px 0px 20px rgba($color: #000000, $alpha: 0.2);
    z-index: 1000;

    img {
      width: 100%;
      height: 100%;
      // aspect-ratio: 3/2;
      object-fit: contain;
      cursor: pointer;
    }
  }
}

[dir="rtl"] {
  .ads-container {
    left: 2vw;
    right: auto;
  }
}

.doubled-ads-container {
  width: 12vw;
  height: 34.9074vh;
  min-width: 260px;
  background: var(--secondary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #d0dbe5;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;

  h1 {
    color: #d0dbe5 !important;
    font-size: 4.27vw;
  }

  overflow: hidden;

  .ad-img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: $Large-Labtop-Breakpoint) {
    min-width: calc(260px * $Laptop-Size-Ratio);
    min-height: 0;
  }

  @media screen and (max-width: $Medium-Labtop-Breakpoint) {
    min-width: calc(260px * $Medium-Size-Ratio);
    min-height: calc(377px * $Medium-Size-Ratio);
  }

  @media screen and (max-width: $Small-Labtop-Breakpoint) {
    min-width: calc(260px * $Small-Size-Ratio);
    min-height: calc(377px * $Small-Size-Ratio);
  }
}

.whiteBg {
  background: #f5f6fa !important;

  h1 {
    color: #d0dbe5 !important;
  }
}