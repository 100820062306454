@media screen and (max-width: 575px) {
  .swiper-wrapper {
    position: relative;
    top: -16vw;
  }

  .swiper-slide-active {
    top: -7vw;
  }

  .swiper-slide-next {
    position: relative;

    .article-headline-card {

      img {
        object-fit: cover;
        height: 16vw !important;
        min-height: 0;
      }

      .article-headline-card-info {
        .article-headline-desc {
          display: block;
        }
      }
    }
  }

  .swiper-slide-prev {
    .article-headline-card {
      .article-headline-card-info {
        .article-headline-desc {
          display: block;
        }
      }
    }
  }

  .swiper-container {
    margin-top: 1.7vh;

    .swiper-slide-prev {
      .article-headline-card {
        background-image: linear-gradient(to bottom,
            rgba(196, 200, 208, 0.5),
            rgba(196, 200, 208, 0.1)) !important;
        border-radius: 24px !important;
        -webkit-border-radius: 24px !important;
        -moz-border-radius: 24px !important;
        -ms-border-radius: 24px !important;
        -o-border-radius: 24px !important;
        background-attachment: fixed;
      }
    }
  }

  .swiper-container {
    .swiper-slide-next {
      .article-headline-card {
        background-image: linear-gradient(to bottom,
            rgba(196, 200, 208, 0.5),
            rgba(196, 200, 208, 0.1)) !important;
      }
    }

    .swiper-slide-next+.swiper-slide {
      .article-headline-card {
        background-image: linear-gradient(to bottom,
            rgba(196, 200, 208, 0.5),
            rgba(196, 200, 208, 0.1)) !important;
      }
    }

    .swiper-slide-next+.swiper-slide+.swiper-slide {
      .article-headline-card {
        background-image: linear-gradient(to bottom,
            rgba(196, 200, 208, 0.5),
            rgba(196, 200, 208, 0.1)) !important;
      }
    }
  }
}